import { IRole } from "@/customTypings/role";
import { useAtom } from "@dbeining/react-atom";
import { faFileExport, faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { userAtom } from "../../atoms/userAtom";
import { ColumnRender, FilterType, IColumn, IFilter, IPagedListAction } from "../../components/PagedList/Declarations";
import { PagedList } from "../../components/PagedList/PagedList";
import { Role } from "../../helpers";
import { AddLeadProductModel } from "./AddLeadProductModel";

type Props = {
    leadId: string | undefined;
};

const LeadProductsPage: React.FC<Props> = ({ leadId }) => {
    const currentUser = useAtom(userAtom);
    const [showAddLeadProduct, setShowAddLeadProduct] = useState(false);
    const [refreshNumber, setRefeshNumber] = useState(0);


    const handleAddLeadProduct = (added: boolean) => {
        if (added) {
            setRefeshNumber((refreshNumber) => refreshNumber + 1);
        }
        setShowAddLeadProduct(false);
    };
    const handleOpenAddOrder = () => {
        setShowAddLeadProduct(true);
    };


    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <PagedList
                            columns={[
                                {
                                    member: "brand",
                                    renderAs: ColumnRender.text,
                                    title: "Brand",
                                    width: 150,
                                } as IColumn,
                                {
                                    member: "model",
                                    renderAs: ColumnRender.text,
                                    title: "Model",
                                    width: 150,
                                } as IColumn,
                                {
                                    member: "color",
                                    renderAs: ColumnRender.text,
                                    title: "Colour",
                                    width: 150,
                                } as IColumn,
                            ]}
                            headActions={[
                                {
                                    icon: faPlus,
                                    text: "Add Product",
                                    onClick: handleOpenAddOrder,
                                } as IPagedListAction,
                            ]}
                            filters={[
                                {
                                    title: "Brand",
                                    member: "brand",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Model",
                                    member: "model",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Colour",
                                    member: "color",
                                    type: FilterType.text,
                                } as IFilter,
                            ]}
                            defaultSortBy="model"
                            getUrl="api/order/leadProducts"
                            avatarUrl="api/avatar/get"
                            rowFormatter={(item: any) => {
                                return null;
                            }}
                            persistanceKey="LeadProductListPage"
                            refreshNumber={refreshNumber + 1}
                            exportUrl={currentUser?.identity.roles.some((role: IRole) => [Role.Admin].indexOf(role.name) >= 0) ? "api/order/download" : undefined}
                            exportIcon={faFileExport}
                        />
                    </Col>
                </Row>

            </Container>
            <AddLeadProductModel show={showAddLeadProduct} onClose={handleAddLeadProduct} leadId={leadId} />
        </>
    );
};

export { LeadProductsPage };
