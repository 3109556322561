import { IOrder } from '@/customTypings/Order';
import { authHeader, handleResponse } from '../helpers';
import { ILeadProduct } from '../customTypings/LeadProduct';

export const orderService = {
    add,
    get,
    edit,
    getAll,
    fullOrder,
    addLeadProduct
};

function add(order: IOrder) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(order),
    };

    return fetch(`api/order/add`, requestOptions)
        .then(handleResponse);
}

function get(id: string) {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`api/order/get?id=${id}`, requestOptions)
        .then(handleResponse);
}

function edit(order: IOrder) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(order),
    };

    return fetch(`api/order/edit`, requestOptions)
        .then(handleResponse);
}

function getAll() {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };

    return fetch(`api/order/getall`, requestOptions)
        .then(handleResponse);
}

function fullOrder(id: string) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`api/order/fullorder?id=${id}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function addLeadProduct(leadProduct: ILeadProduct) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(leadProduct),
    };

    return fetch(`api/order/addLeadProduct`, requestOptions)
        .then(handleResponse);
}