import { IJobCustomerDetails } from '@/customTypings/JobCustomerDetails';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faBriefcase, faLocationDot, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IJob } from '../../customTypings/Job';
import { jobService } from '../../services';
import { User } from '../User';
import { IEvent } from './EventCalendar';
dayjs.extend(utc);

type Props = {
    show: boolean
    onClose: (update: boolean) => void
    event?: IEvent
    updateEventAllowed: boolean
    }

const EventDetails: React.FC<Props> = ({ show, onClose, event, updateEventAllowed }) => {
    let navigate = useNavigate();
        
    const [job, setJob] = useState<IJob>()
    const [jobCustomerDetails, setJobCustomerDetails] = useState<IJobCustomerDetails>();
    const [address, setAddress] = useState("");

    const handleClose = () => onClose(false);
  
    useEffect(() => {
        if(event && event.jobId){
            jobService.get(event.jobId).then(job => setJob(job))
            jobService.getCustomerDetails(event.jobId).then((customerDetails: IJobCustomerDetails) => {
                setJobCustomerDetails(customerDetails);
                if(customerDetails.address){
                    var address = ""
                    address += customerDetails.address.line1
                    if(customerDetails.address.line2 && customerDetails.address.line2.trim())
                    {
                        address += ", "+customerDetails.address.line2
                    }
                    if(customerDetails.address.line3 && customerDetails.address.line3.trim())
                    {
                        address += ", "+customerDetails.address.line3
                    }
                    if(customerDetails.address.town && customerDetails.address.town.trim())
                    {
                        address += ", "+customerDetails.address.town
                    }
                    if(customerDetails.address.county && customerDetails.address.county.trim())
                    {
                        address += ", "+customerDetails.address.county
                    }
                    if(customerDetails.address.postcode && customerDetails.address.postcode.trim())
                    {
                        address += ", "+customerDetails.address.postcode
                    }
                    setAddress(address)
                }
            });
        }
    }, [event]);

    return (
        <Modal
            centered 
            show={show}
            keyboard={false}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>{event?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {job?.jobNumber ? <Row>
                    <Col md={1}><FontAwesomeIcon icon={faBriefcase} /></Col>
                    <Col md={3}>Job Number:</Col>
                    <Col md={8}>
                        <Button className='p-0' variant='link' onClick={() => navigate('/jobs/'+job.id)}>
                            {job.jobNumber}
                        </Button>
                    </Col>
                </Row> :
                <Row>
                    <Col md={1}><FontAwesomeIcon icon={faBriefcase} /></Col>
                    <Col md={3}>Status:</Col>
                    <Col md={8}>{job?.status.valueOf()}</Col>
                </Row>}
                <Row>
                    <Col md={1}><FontAwesomeIcon icon={faCalendar} /></Col>
                    <Col md={3}>Start</Col>
                    <Col md={8}>{dayjs(event?.start).format(event?.allDay ? 'dddd DD MMMM YYYY' : 'dddd DD MMMM YYYY HH:mm')}</Col>
                </Row>
                <Row>
                    <Col md={{ span: 3, offset: 1 }}>End</Col>
                    <Col md={8}>{dayjs(event?.end).format(event?.allDay ? 'dddd DD MMMM YYYY' : 'dddd DD MMMM YYYY HH:mm')}</Col>
                </Row>
                <hr/>
                {jobCustomerDetails && 
                <>
                    {jobCustomerDetails.address && <>
                        <Row>
                            <Col md={1}><FontAwesomeIcon icon={faLocationDot} /></Col>
                            <Col md={11}>
                                {jobCustomerDetails.address.latitude && jobCustomerDetails.address.longitude ?
                                    <a 
                                    href={"https://maps.google.com/?q=" + jobCustomerDetails.address.latitude + "," + jobCustomerDetails.address.longitude} 
                                    target="_blank" rel="noreferrer"
                                    >
                                        {address}
                                    </a> :
                                    <div>{address}</div>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 11, offset: 1 }}>
                                <a 
                                    href={"https://what3words.com/" + jobCustomerDetails.address.what3words} 
                                    target="_blank" rel="noreferrer"
                                >
                                    {"///" + jobCustomerDetails.address.what3words}
                                </a>
                            </Col>
                        </Row>
                        <hr/>
                    </>}
                    <Row>
                        <Col md={1}><FontAwesomeIcon icon={faUser} /></Col>
                        <Col md={11}>
                            <Stack>
                                <span>
                                    <Button className='p-0' variant='link' onClick={() => navigate('/customers/'+jobCustomerDetails.propertyOwnerPreferredContact?.id)}>
                                        {jobCustomerDetails.propertyOwnerPreferredContact?.name}
                                    </Button>
                                </span>
                                <span>{jobCustomerDetails.propertyOwnerPreferredContact?.emailAddress}</span>
                                <span>{jobCustomerDetails.propertyOwnerPreferredContact?.mobilePhone}</span>
                            </Stack>
                        </Col>
                    </Row>
                    <hr/>
                </>
                }
                <Row>
                    <Col>Attendees</Col>
                </Row>
                {event?.associatedIds?.map(associatedId => 
                    <Row key={associatedId}>
                        <User userId={associatedId} />
                    </Row>
                )}
            </Modal.Body>
            <Modal.Footer>
                <div className="form-group">
                    {event!==undefined && event.taskId!==null && <Button
                        variant="primary"
                        onClick={() => navigate('/mytasks/'+event.taskId)}
                        className="me-2"
                    >
                        View Task
                    </Button>}
                    {updateEventAllowed && <Button 
                        variant="primary" 
                        onClick={() => {
                            onClose(true)
                        }}
                        className="me-2" 
                        disabled={!updateEventAllowed}
                    >
                        Edit
                    </Button>}
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export { EventDetails };

