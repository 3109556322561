import { useAtom } from "@dbeining/react-atom";
import { ErrorMessage, Formik } from "formik";
import { useRef, useState } from "react";
import {
    Alert, Breadcrumb, Button, Col, Container, Form, InputGroup, Row,
    Stack
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import ReactQuill from "react-quill";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { userAtom } from "../../atoms/userAtom";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import {
    EMAIL_TEMPLATE_PLACEHOLDERS
} from "../../constants/EmailTemplate";
import { emailTemplateService } from "../../services";
import HHTDropDown from "../../Shared/HHTDropDown";
import HHTField from "../../Shared/HHTField";
import HHTRadioButton from "../../Shared/HHTRadioButton";
import SiteVisitFormModal from "./SiteVisitFormModal";
import TemplatePage from "./TemplatePage";

const EmailTemplateEditPage: React.FC = () => {
    const [alertVariant, setAlertVariant] = useState("danger");

    const { state } = useLocation();
    const quillRef = useRef<any>();
    const labelSpan = 3;
    const fieldSpan = 9;
    const item = state.item;
    const title: string = state?.item?.name;
    const placeholders = EMAIL_TEMPLATE_PLACEHOLDERS[title];
    const currentUser = useAtom(userAtom);
    const RENDER_PDF_ATTRIBUTE =
        !title.includes("Signing") &&
        state?.templateAttributes.includes("attachPDFForm");
    const templateAttributes = state?.templateAttributes.filter(
        (attribute: string) => attribute !== "attachPDFForm"
    );
    const signingForms = [
        "Site Visit Form for Signing",
        "Endless Pools Order Form For Signing",
        "Delivery/Installation Form for Signing",
        "Hot Tub Order Form For Signing",
        "Spa Fresh Service Report for Signing",
        "Annual Service Report for Signing"
    ];

return (
    <Container fluid>
        <Helmet>
            <title> {title}</title>
        </Helmet>

        <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/settings" }}>
                Settings
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/EmailTemplates" }}>
                Automatic Emails
            </Breadcrumb.Item>
            <Breadcrumb.Item active> {title} </Breadcrumb.Item>
        </Breadcrumb>
        <>
            <Formik
                initialValues={item}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required("Field is required"),
                    subject: Yup.string().required("Field is required"),
                    body: Yup.string().required("Field is required"),
                })}
                onSubmit={(
                    values,
                    { setStatus, setSubmitting, setFieldError, resetForm }
                ) => {
                    setStatus();
                    setSubmitting(false);
                    emailTemplateService
                        .edit({
                            ...values,
                            lastModifiedBy: currentUser?.identity.displayName,
                            lastModifiedTime: new Date(),
                        })
                        .then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                    setAlertVariant("success");
                                    resetForm();
                                    setStatus("Succsessfully Updated");
                                } else {
                                    setAlertVariant("danger");
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setAlertVariant("danger");
                                if (error.status === 400) {
                                    setStatus(error.title);
                                    setFieldError("name", error.errors.Name);
                                    setFieldError("type", error.errors.type);
                                } else {
                                    setStatus(error);
                                }
                                setSubmitting(false);
                            }
                        );
                }}
            >
                {({
                    values,
                    errors,
                    status,
                    touched,
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    setStatus,
                    setFieldValue,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className="d-flex justify-content-between">
                            <h1 className="my-3">{title}</h1>

                            <div className="d-flex">
                                <p className="me-4"> Enabled</p>
                                <div className="form-check form-switch">
                                    <input
                                        name="enabled"
                                        onChange={handleChange}
                                        checked={values.enabled}
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                    />
                                </div>
                            </div>
                        </div>
                        <Stack gap={3}>
                            <Form.Group as={Row} controlId="subject">
                                <Form.Label column xs={6} lg={6} xl={labelSpan - 2}>
                                    Subject
                                </Form.Label>
                                <Col xs={12} lg={12} xl={fieldSpan}>
                                    <HHTField
                                        name="subject"
                                        type="text"
                                        handleChange={handleChange}
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Col>
                            </Form.Group>
                            {RENDER_PDF_ATTRIBUTE && (
                                <Form.Group as={Row} controlId="subject">
                                    <Form.Label column xs={6} lg={6} xl={labelSpan}>
                                        Attach PDF form
                                    </Form.Label>

                                    <Col xs={12} lg={12} xl={fieldSpan}>
                                        <HHTRadioButton
                                            name="attachPDFForm"
                                            values={values}
                                            handleChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                            )}

                            <Form.Group as={Row} controlId="body">
                                <Form.Label column xs={6} lg={6} xl={labelSpan - 2}>
                                    Body
                                </Form.Label>
                                <Col xs={6} lg={6} xl={2} align="end">
                                    {title.includes("Site Visit Form") ? (
                                        <SiteVisitFormModal
                                            clickHandler={(value: string) => {
                                                setFieldValue("body", values.body + value);
                                            }}
                                            data={placeholders}
                                        />
                                    ) : (
                                        <HHTDropDown
                                            title="Insert Placeholders"
                                            placeholders={Object.keys(placeholders)}
                                            setFieldValue={setFieldValue}
                                            values={values}
                                            clickHandler={(value: string) => {
                                                value && setFieldValue("body", values.body + value);
                                            }}
                                        />
                                    )}
                                </Col>
                                <Col xs={12} lg={12} xl={fieldSpan}>
                                    <InputGroup hasValidation>
                                        <ReactQuill
                                            ref={quillRef}
                                            value={values.body ?? ""}
                                            onChange={(value, delta, source, editor) => {
                                                setFieldValue("body", value);
                                                setFieldValue(
                                                    "bodyCursorLocation",
                                                    editor.getSelection(true).index ?? 0
                                                );
                                            }}
                                            onBlur={(previousSelection, source, editor) => {
                                                setFieldValue(
                                                    "bodyCursorLocation",
                                                    previousSelection?.index ?? 0
                                                );
                                            }}
                                        />
                                        <ErrorMessage
                                            name="body"
                                            component="div"
                                            className="invalid-feedback"
                                            render={(errorMessage) => (
                                                <Form.Control.Feedback type="invalid">
                                                    {errorMessage}
                                                </Form.Control.Feedback>
                                            )}
                                        />
                                    </InputGroup>
                                </Col>
                            </Form.Group>

                            {!signingForms.some(form => title.includes(form)) && (
                                <TemplatePage
                                    values={values}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    templateAttributes={templateAttributes}
                                />
                            )}


                            <Form.Group as={Row} controlId="sendto">
                                <Form.Label column xs={6} lg={6} xl={labelSpan}>
                                    Last Modified Time
                                </Form.Label>
                                <Col xs={6} lg={6} xl={fieldSpan}>
                                    {item.lastModifiedTime}
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="sendto">
                                <Form.Label column xs={6} lg={6} xl={labelSpan}>
                                    Last Modified By
                                </Form.Label>
                                <Col xs={6} lg={6} xl={fieldSpan}>
                                    {item.lastModifiedBy}
                                </Col>
                            </Form.Group>

                            {status && (
                                <Alert
                                    variant={alertVariant}
                                    className="mt-3"
                                    onClose={() => setStatus()}
                                    dismissible
                                >
                                    {status}
                                </Alert>
                            )}
                            <Form.Group>
                                <Stack direction="horizontal" gap={3}>
                                    <div className="ms-auto">
                                        <Button
                                            variant="success"
                                            disabled={isSubmitting}
                                            type="submit"
                                        >
                                            {isSubmitting ? (
                                                <LoadingSpinner text="Saving..." />
                                            ) : (
                                                "Save"
                                            )}
                                        </Button>
                                    </div>
                                </Stack>
                            </Form.Group>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </>
    </Container>
);
};

export default EmailTemplateEditPage;
