import { IAddress } from "@/customTypings/Address";
import { IContact } from "@/customTypings/Contact";
import { IContactFull } from "@/customTypings/ContactFull";
import { ICustomer } from "@/customTypings/Customer";
import { ICustomerContactNew } from "@/customTypings/CustomerContactNew";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, InputGroup, Modal, Placeholder, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import * as Yup from "yup";
import { phoneRegExp } from "../../helpers/utils";
import { customerService } from "../../services";
import { LoadingSpinner } from "../LoadingSpinner";

type Props = {
    show: boolean;
    onClose: (updated: boolean) => void;
    customerId: string;
    contactFull: IContactFull;
};

const EditGivenCustomerContactModel: React.FC<Props> = ({ show, onClose, customerId, contactFull }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [currentCustomer, setCurrentCustomer] = useState<ICustomer>();
    const [loading, setLoading] = useState(true);

    const handleClose = () => onClose(false);

    useEffect(() => {
        if (show) {
            setLoading(true);
            customerService.get(customerId).then((customer) => {
                setCurrentCustomer(customer);
                setLoading(false);
            });
        }
    }, [customerId, show]);

    const labelSpan = 3;
    const fieldSpan = 9;

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose} size="lg">
            {loading || !currentCustomer ? (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Contact</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Name</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Phone</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Email</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Address Line 1</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Address Line 2</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Address Line 3</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Town</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>County</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Postcode</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="6rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>What3words</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Placeholder.Button variant="primary" xs={2} className="me-2" />
                        <Placeholder.Button variant="secondary" xs={1} />
                    </Modal.Footer>
                </>
            ) : (
                <Formik
                    initialValues={{
                        contactName: contactFull.name ?? "",
                        contactMobilePhone: contactFull.mobilePhone ?? "",
                        contactEmail: contactFull.emailAddress ?? "",
                        contactWorkPhone: contactFull.workPhone ?? "",
                        contactEmail2: contactFull.email2 ?? "",
                        showContactDepartment: currentCustomer.isBusiness,
                        contactDepartment: contactFull.department ?? "",
                        contactAddressLine1: contactFull.address?.line1 ?? "",
                        contactAddressLine2: contactFull.address?.line2 ?? "",
                        contactAddressLine3: contactFull.address?.line3 ?? "",
                        contactAddressTown: contactFull.address?.town ?? "",
                        contactAddressCounty: contactFull.address?.county ?? "",
                        contactAddressPostcode: contactFull.address?.postcode ?? "",
                        contactAddressWhat3Words: contactFull.address?.what3words ?? "",
                    }}
                    validationSchema={Yup.object().shape({
                        showContactDepartment: Yup.boolean(),

                        contactName: Yup.string().required("Field is required"),
                        contactMobilePhone: Yup.string().matches(phoneRegExp, "Mobile Phone is not valid").required("Field is required"),
                        contactEmail: Yup.string().required("Field is required"),
                        contactDepartment: Yup.string().when("showContactDepartment", {
                            is: true,
                            then: Yup.string().required("Field is required"),
                        }),
                        contactAddressLine1: Yup.string().required("Field is required"),
                        contactAddressTown: Yup.string().required("Field is required"),
                        contactAddressCounty: Yup.string().required("Field is required"),
                        contactAddressPostcode: Yup.string().required("Field is required"),
                        contactAddressWhat3Words: Yup.string().required("Field is required"),
                    })}
                    onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                        setStatus();
                        setSubmitting(true);
                        const updatedContact: IContact = {
                            id: contactFull.id,
                            name: values.contactName,
                            mobilePhone: values.contactMobilePhone,
                            workPhone: values.contactWorkPhone,
                            emailAddress: values.contactEmail,
                            email2: values.contactEmail2,
                        };
                        var updatedAddress: IAddress | undefined;
                        if (
                            values.contactAddressLine1 !== "" &&
                            values.contactAddressTown !== "" &&
                            values.contactAddressCounty !== "" &&
                            values.contactAddressPostcode !== "" &&
                            values.contactAddressWhat3Words !== ""
                        ) {
                            updatedAddress = {
                                id: contactFull.address?.id,
                                line1: values.contactAddressLine1,
                                line2: values.contactAddressLine2 === "" ? undefined : values.contactAddressLine2,
                                line3: values.contactAddressLine3 === "" ? undefined : values.contactAddressLine3,
                                town: values.contactAddressTown,
                                county: values.contactAddressCounty,
                                postcode: values.contactAddressPostcode,
                                what3words: values.contactAddressWhat3Words,
                                latitude: undefined,
                                longitude: undefined,
                            };
                        }
                        const updatedCustomerContact: ICustomerContactNew = {
                            id: contactFull.customerContactId,
                            customerId: customerId,
                            contact: updatedContact,
                            address: updatedAddress,
                            department: values.contactDepartment === "" ? undefined : values.contactDepartment,
                            isPrimaryContact: contactFull.isPrimaryContact ?? false,
                        };
                        customerService.editContact(updatedCustomerContact).then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                    onClose(true);
                                } else {
                                    setAlertVariant("danger");
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setAlertVariant("danger");
                                if (error.status === 400) {
                                    setStatus(error.title);
                                    setFieldError("contactName", error.errors.Name);
                                    setFieldError("contactMobilePhone", error.errors.MobilePhone);
                                    setFieldError("contactEmail", error.errors.EmailAddress);
                                    setFieldError("contactDepartment", error.errors.Department);
                                    setFieldError("contactAddressLine1", error.errors.Line1);
                                    setFieldError("contactAddressLine2", error.errors.Line2);
                                    setFieldError("contactAddressLine3", error.errors.Line3);
                                    setFieldError("contactAddressTown", error.errors.Town);
                                    setFieldError("contactAddressCounty", error.errors.County);
                                    setFieldError("contactAddressPostcode", error.errors.Postcode);
                                    setFieldError("contactAddressWhat3Words", error.errors.What3Words);
                                } else {
                                    setStatus(error);
                                }
                                setSubmitting(false);
                            }
                        );
                    }}
                >
                    {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Contact</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group as={Row} className="mb-3" controlId="contactName">
                                    <Form.Label column sm={labelSpan}>
                                        Name
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field name="contactName" type="text" className={"form-control" + (errors.contactName && touched.contactName ? " is-invalid" : "")} />
                                        <ErrorMessage name="contactName" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="contactMobilePhone">
                                    <Form.Label column sm={labelSpan}>
                                        Mobile Phone
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field
                                            type="text"
                                            name="contactMobilePhone"
                                            className={`form-control ${touched.contactMobilePhone && errors.contactMobilePhone ? "is-invalid" : ""}`}
                                        />
                                        <ErrorMessage name="contactMobilePhone" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="contactWorkPhone">
                                    <Form.Label column sm={labelSpan}>
                                        Work Phone
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field
                                            type="text"
                                            name="contactWorkPhone"
                                            className={`form-control ${touched.contactWorkPhone && errors.contactWorkPhone ? "is-invalid" : ""}`}
                                        />
                                        <ErrorMessage name="contactWorkPhone" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="contactEmail">
                                    <Form.Label column sm={labelSpan}>
                                        Email
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field name="contactEmail" type="text" className={"form-control" + (errors.contactEmail && touched.contactEmail ? " is-invalid" : "")} />
                                        <ErrorMessage name="contactEmail" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="contactEmail2">
                                    <Form.Label column sm={labelSpan}>
                                        Email 2
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field
                                            type="email"
                                            name="contactEmail2"
                                            className={`form-control ${touched.contactEmail2 && errors.contactEmail2 ? "is-invalid" : ""}`}
                                        />
                                        <ErrorMessage name="contactEmail2" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                {values.showContactDepartment && (
                                    <Form.Group as={Row} className="mb-3" controlId="contactDepartment">
                                        <Form.Label column sm={labelSpan}>
                                            Department
                                        </Form.Label>
                                        <Col sm={fieldSpan}>
                                            <Field name="contactDepartment" type="text" className={"form-control" + (errors.contactDepartment && touched.contactDepartment ? " is-invalid" : "")} />
                                            <ErrorMessage name="contactDepartment" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                )}
                                <Form.Group as={Row} className="mb-3" controlId="contactAddressLine1">
                                    <Form.Label column sm={labelSpan}>
                                        Address Line 1
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field name="contactAddressLine1" type="text" className={"form-control" + (errors.contactAddressLine1 && touched.contactAddressLine1 ? " is-invalid" : "")} />
                                        <ErrorMessage name="contactAddressLine1" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="contactAddressLine2">
                                    <Form.Label column sm={labelSpan}>
                                        Address Line 2
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field name="contactAddressLine2" type="text" className={"form-control" + (errors.contactAddressLine2 && touched.contactAddressLine2 ? " is-invalid" : "")} />
                                        <ErrorMessage name="contactAddressLine2" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="contactAddressLine3">
                                    <Form.Label column sm={labelSpan}>
                                        Address Line 3
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field name="contactAddressLine3" type="text" className={"form-control" + (errors.contactAddressLine3 && touched.contactAddressLine3 ? " is-invalid" : "")} />
                                        <ErrorMessage name="contactAddressLine3" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="contactAddressTown">
                                    <Form.Label column sm={labelSpan}>
                                        Town
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field name="contactAddressTown" type="text" className={"form-control" + (errors.contactAddressTown && touched.contactAddressTown ? " is-invalid" : "")} />
                                        <ErrorMessage name="contactAddressTown" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="contactAddressCounty">
                                    <Form.Label column sm={labelSpan}>
                                        County
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field
                                            name="contactAddressCounty"
                                            type="text"
                                            className={"form-control" + (errors.contactAddressCounty && touched.contactAddressCounty ? " is-invalid" : "")}
                                        />
                                        <ErrorMessage name="contactAddressCounty" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="contactAddressPostcode">
                                    <Form.Label column sm={labelSpan}>
                                        Postcode
                                    </Form.Label>
                                    <Col sm={labelSpan}>
                                        <Field
                                            name="contactAddressPostcode"
                                            type="text"
                                            className={"form-control" + (errors.contactAddressPostcode && touched.contactAddressPostcode ? " is-invalid" : "")}
                                        />
                                        <ErrorMessage name="contactAddressPostcode" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="contactAddressWhat3Words">
                                    <Form.Label column sm={labelSpan}>
                                        What3Words
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text>{"///"}</InputGroup.Text>
                                            <Form.Control
                                                type={"text"}
                                                name="contactAddressWhat3Words"
                                                value={values.contactAddressWhat3Words}
                                                onChange={handleChange}
                                                isInvalid={!!errors.contactAddressWhat3Words}
                                            />
                                            <Button href={"https://what3words.com/" + values.contactAddressWhat3Words} target="_blank">
                                                <FontAwesomeIcon icon={faExternalLinkAlt} /> View
                                            </Button>
                                            <Form.Control.Feedback type="invalid">{errors.contactAddressWhat3Words}</Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                {status && (
                                    <Alert variant={alertVariant} className="mt-3">
                                        {status}
                                    </Alert>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="form-group">
                                    <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                        {isSubmitting ? <LoadingSpinner text="Updating contact..." /> : "Update contact"}
                                    </Button>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            )}
        </Modal>
    );
};

export { EditGivenCustomerContactModel };
