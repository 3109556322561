import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { customerContact } from "../../constants/EmailTemplate";
import HHTRadioButton from "../../Shared/HHTRadioButton";
import HHTSelect from "../../Shared/HHTSelect";
interface TemplatePageProps {
  values: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  templateAttributes: any;
  setFieldValue: any;
}

function convertToTitle(str: string) {
  return str
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const TemplatePage: React.FC<TemplatePageProps> = ({
  values,
  handleChange,
  templateAttributes,
  setFieldValue
}) => {
  const labelSpan = 3;
  const fieldSpan = 9;

  const DROPDOWN_ATTRIBUTE = "customerContact";

  const exclude = [
    "id",
    "emailTemplate",
    "emailTemplateId",
    DROPDOWN_ATTRIBUTE,
  ];
  const attributes = templateAttributes.filter(
    (item: any) => !exclude.includes(item)
  );



  const renderRadioButton = (index: number, offset: number) => {
    const currentIndex = index + offset;
    return (
      currentIndex < attributes.length && (
        <>
          <Form.Label column xs={3} lg={3} xl={3}>
            {convertToTitle(attributes[currentIndex])}
          </Form.Label>
          <Col xs={3} lg={3} xl={3}>
            <HHTRadioButton
              name={attributes[currentIndex]}
              handleChange={handleChange}
              values={values}
            />
          </Col>
        </>
      )
    );
  };

  return (
    <>
      {attributes.map((_: any, index: number) => {
        const pairIndex = index * 2;
        if (pairIndex >= attributes.length) return null;

        return (
          <Form.Group as={Row} controlId="sendto" key={pairIndex}>
            <Form.Label column xs={6} lg={6} xl={labelSpan}>
              {index === 0 && "Send to"}
            </Form.Label>

            <Col xs={6} lg={6} xl={fieldSpan}>
              <Form.Group as={Row} controlId={`sendto-${pairIndex}`}>
                {renderRadioButton(pairIndex, 0)}
                {renderRadioButton(pairIndex, 1)}
              </Form.Group>
            </Col>
          </Form.Group>
        );
      })}
 
      <Form.Group as={Row} controlId="sendto">
        <Col xs={6} lg={6} xl={labelSpan}></Col>
        <Col xs={6} lg={6} xl={fieldSpan}>
          {templateAttributes.includes(DROPDOWN_ATTRIBUTE) && (
            <Form.Group as={Row} controlId="sendto">
              <Form.Label column xs={3} lg={3} xl={3}>
                Customer Contact
              </Form.Label>
              <Col xs={3} lg={3} xl={3}>
                <HHTSelect
                  placeholders={customerContact}
                  name="customerContact"
                  isMulti={false}
                  values={values}
                setFieldValue={setFieldValue}
                />
              </Col>
            </Form.Group>
          )}
        </Col>
      </Form.Group>
    </>
  );
};

export default TemplatePage;
