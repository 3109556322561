import { ICommunicationLog } from "@/customTypings/CommunicationLog";
import { useAtom } from "@dbeining/react-atom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import {
    Alert, Button, Card, Col, Container, Form, Row,
    Stack
} from "react-bootstrap";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { FileUpload } from "use-file-upload";
import * as Yup from "yup";
import { userAtom } from "../../atoms/userAtom";
import { DocumentLibrary } from "../../constants/DocumentLibraryFolderId";
import { Role } from "../../helpers";
import { customerService, documentService } from "../../services";
import { communicationLogService } from "../../services/communicationlog.service";
import HHTField from "../../Shared/HHTField";
import { ReactSelectBootstrapStyle } from "../../styles/ReactSelectBootstrapStyle";
import { LoadingSpinner } from "../LoadingSpinner";
export default function AddCommunication() {
    const navigate = useNavigate();
    const currentUser = useAtom(userAtom);
    const isAdmin = currentUser?.identity.roles.some(
        (role) => role.name === Role.SystemAdmin
    );
    const [alertVariant, setAlertVariant] = useState("danger");
    const [allCustomers, setAllCustomers] = useState<any[]>();
    const quillRef = useRef<any>();

    useEffect(() => {
        Promise.all([customerService.getAllListItems()]).then((response) => {
            setAllCustomers(response[0]);
        });
    }, [])

    const FilePreview = ({ file }: any) => {
        const [expanded, setExpanded] = useState(false);

        const handleClick = () => {
            setExpanded(!expanded);
        };

        const renderPreview = () => {
            if (file.type.startsWith("image") || file.type === "application/pdf") {
                return (
                    <div onClick={handleClick}>
                        {file.type.startsWith("image") && (
                            <>
                                {expanded ? (
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                        style={{ width: "100%", transition: "width 0.5s" }}
                                    />
                                ) : (
                                    <button>
                                        <i className="fa fa-file" style={{ fontSize: "48px" }}></i>
                                        <div>{file.name}</div>
                                    </button>
                                )}
                            </>
                        )}
                        {file.type === "application/pdf" && (
                            <>
                                {expanded ? (
                                    <embed
                                        src={URL.createObjectURL(file)}
                                        type="application/pdf"
                                        style={{ width: "100%", transition: "width 0.5s" }}
                                    />
                                ) : (
                                    <button>
                                        <i className="fa fa-file" style={{ fontSize: "48px" }}></i>
                                        <div>{file.name}</div>
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                );
            } else {
                return (
                    <div onClick={handleClick}>
                        <div>{file.name}</div>
                    </div>
                );
            }
        };

        return (
            <Card.Text style={{ cursor: "pointer" }} onClick={handleClick}>
                {renderPreview()}
            </Card.Text>
        );
    };

    const labelSpan = 3;
    const fieldSpan = 9;

    const save = async (
        values: any,
        resetForm: any,
        setSubmitting: any,
        setStatus: any
    ) => {
        const communicationlog: ICommunicationLog = {
            id: undefined,
            customerId: values.customerId,
            date: values.date,
            from: values.from,
            to: values.to,
            files: values.files,
            subject: values.subject,
            body: values.body,
        };

        try {
            const response = await communicationLogService.create(communicationlog);
            setSubmitting(false);

            if (response.status !== "Failure") {
                const files = (values.files as FileUpload[]).map((f) => f.file);

                documentService
                    .uploadFile(DocumentLibrary.folderId, files, response.id as string)
                    .catch((rejected) => {
                        setAlertVariant("Could not upload file: " + rejected);
                    });
                setAlertVariant("success");
                resetForm();
                setStatus("Successfully added");
                isAdmin ? navigate(`/admincommunicationlog`) : navigate(`/communicationlog`);
            } else {
                setAlertVariant("danger");
                setStatus(response.message);
            }
        } catch (error: any) {
            setAlertVariant("danger");
            setStatus(error.message || "An unexpected error occurred");
            setSubmitting(false);
        }
    };
    return (
        <Container fluid>
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <h1 className="my-3">New Communication Log</h1>
                </div>

                <Formik
                    initialValues={{
                        date: new Date().toISOString().split('T')[0],
                        customerId: "",
                        from: "",
                        to: "",
                        files: [],
                        subject: "",
                        body: "",
                    }}
                    validationSchema={Yup.object().shape({
                        date: Yup.date().required("Date is required"),
                        customerId: Yup.string().required("Customer is required"),
                        from: Yup.string()
                            .required("From field is required"),
                        to: Yup.string()
                            .required("To field is required"),
                        files: Yup.array().min(0, "Please select at least one file"),
                        subject: Yup.string().required("Subject is required"),
                        body: Yup.string().required("Body is required"),
                    })}
                    onSubmit={(
                        values,
                        { setStatus, setSubmitting, setFieldError, resetForm }
                    ) => {
                        setStatus();
                        setSubmitting(false);
                        save(values, resetForm, setSubmitting, setStatus);
                    }}
                >
                    {({
                        values,
                        errors,
                        status,
                        touched,
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        setStatus,
                        setFieldValue,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <Stack gap={3}>
                                <Form.Group as={Row} controlId="date">
                                    <Form.Label column xs={3} lg={3}>
                                        Date
                                    </Form.Label>
                                    <Col xs={3} lg={3}>
                                        <HHTField
                                            name="date"
                                            type="date"
                                            handleChange={handleChange}
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>


                                    <Form.Label column xs={3} lg={3} className="text-center">
                                        Customer
                                    </Form.Label>
                                    <Col xs={3} lg={3} >
                                        <Select
                                            styles={ReactSelectBootstrapStyle}
                                            className="stateManagedSelect"
                                            onChange={(value) => setFieldValue("customerId", value == null ? "" : value.value)}
                                            isClearable
                                            options={allCustomers}
                                        />
                                        <ErrorMessage name="customerId" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="CustomerId">

                                </Form.Group>

                                <Form.Group as={Row} controlId="from">
                                    <Form.Label column xs={12} lg={12} xl={labelSpan}>
                                        From
                                    </Form.Label>
                                    <Col xs={12} lg={12} xl={fieldSpan}>
                                        <HHTField
                                            name="from"
                                            type="text"
                                            handleChange={handleChange}
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="to">
                                    <Form.Label column xs={12} lg={12} xl={labelSpan}>
                                        To
                                    </Form.Label>
                                    <Col xs={12} lg={12} xl={fieldSpan}>
                                        <HHTField
                                            name="to"
                                            type="text"
                                            handleChange={handleChange}
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="files">
                                    <Form.Label column xs={12} lg={12} xl={labelSpan}>
                                        Files
                                    </Form.Label>
                                    <Col xs={12} lg={12} xl={fieldSpan}>
                                        <label htmlFor="fileInput">
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                type="button"
                                                className="btn btn-primary p-2"
                                            />
                                        </label>
                                        <input
                                            type="file"
                                            onChange={(event: any) =>
                                                setFieldValue("files", Array.from(event.currentTarget.files))
                                            }
                                            id="fileInput"
                                            className={`d-none form-control ${errors["files"] && touched["files"] ? "is-invalid" : ""
                                                }`}
                                            multiple
                                            name="files[]"
                                        />
                                        <ErrorMessage
                                            name="files"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                        <div className="mt-2">
                                            {values.files.length > 0 && (
                                                <Card>
                                                    <Card.Header as="h5">Selected Files</Card.Header>
                                                    <Card.Body>
                                                        {values.files.map((file, index) => (
                                                            <FilePreview key={index} file={file} />
                                                        ))}
                                                    </Card.Body>
                                                </Card>
                                            )}
                                        </div>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="to">
                                    <Form.Label column xs={12} lg={12} xl={labelSpan}>
                                        Subject
                                    </Form.Label>
                                    <Col xs={12} lg={12} xl={fieldSpan}>
                                        <HHTField
                                            name="subject"
                                            type="text"
                                            handleChange={handleChange}
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="body">
                                    <Form.Label column xs={12} lg={12} xl={labelSpan}>
                                        Body
                                    </Form.Label>

                                    <Col xs={12} lg={12} xl={fieldSpan}>
                                        <ReactQuill
                                            className={`${errors["body"] && touched["body"] ? "is-invalid" : ""
                                                }`}
                                            ref={quillRef}
                                            value={values.body ?? ""}
                                            onChange={(value) => {
                                                setFieldValue("body", value);
                                            }}
                                        />
                                        <ErrorMessage
                                            name="body"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </Col>
                                </Form.Group>

                                {status && (
                                    <Alert
                                        variant={alertVariant}
                                        className="mt-3"
                                        onClose={() => setStatus()}
                                        dismissible
                                    >
                                        {status}
                                    </Alert>
                                )}
                                <Form.Group>
                                    <Stack direction="horizontal" gap={3}>
                                        <div className="ms-auto">
                                            <Button variant="primary" type="submit">
                                                {isSubmitting ? (
                                                    <LoadingSpinner text="Saving..." />
                                                ) : (
                                                    "Save"
                                                )}
                                            </Button>
                                        </div>
                                    </Stack>
                                </Form.Group>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </>
        </Container>
    );
}
