import { useAtom } from "@dbeining/react-atom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, FormCheck, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { userAtom } from "../../atoms/userAtom";
import { LoadingSpinner } from "../LoadingSpinner";
import { ITask } from "../../customTypings/Task";
import { addTaskToCalender } from "../../helpers/tasks";
import { customerService, emailTemplateService, productService, taskService, userService } from "../../services";
import { ReactSelectBootstrapStyle } from "../../styles/ReactSelectBootstrapStyle";
import mapEmailBody from "../../pages/Task/Mapping";
import { RecurringAddTask } from "../../pages/Task/RecurringAddTask";
import { IValueAndLabel } from "@/customTypings/ValueAndLabel";
import { bool } from "yup";
dayjs.extend(utc);


type Props = {
    customerId: string | undefined | null;
    show: boolean;
    onClose: (added: boolean) => void;
    fromLead?: boolean;
    location?: string;
};

const TaskAddModel: React.FC<Props> = ({ customerId, show, onClose, fromLead = false, location = "" }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [allProducts, setAllProducts] = useState<any[]>();
    const [allCustomers, setAllCustomers] = useState<any[]>();
    const [allUsers, setAllUsers] = useState<any[]>();
    const [allTaskTypes, setAllTaskTypes] = useState<any[]>();
    const [visibleRecurring, setVisibleRecurring] = useState(false)
    const [customerField, setCustomerField] = useState<IValueAndLabel>();
    const [emailTemplate, setEmailTemplate] = useState<any>({});
    const [taskTemplate, setTaskTemplate] = useState<any>({});
    const [adminEmail, setAdminEmail] = useState("");

    const handleClose = () => onClose(false);
    const currentUser = useAtom(userAtom);
    const actioner = `${currentUser?.identity.displayName}: ${currentUser?.identity.email}`;

    let getRoundedDate = (minutes: number, d = new Date()) => {
        let ms = 1000 * 60 * minutes; // convert minutes to ms
        let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);

        return roundedDate;
    };

    const calculateEndTime = (startTime: string): string => {
        const [hours, minutes] = startTime.split(':').map(Number);
        return dayjs().hour(hours).minute(minutes).add(1, 'hour').format("HH:mm");
    };

    useEffect(() => {
        Promise.all([productService.getAllListItems(), customerService.getAllListItems(),
        userService.getAllListItems(), taskService.getAllListTaskTypeItems(), userService.getAdminEmail()]).then((response) => {
            setAllProducts(response[0]);
            setAllCustomers(response[1]);
            setAllUsers(response[2]);
            setAllTaskTypes(response[3]);
            setAdminEmail(response[4]);
            const customer=response[1].find((customer:IValueAndLabel) => customer.value === customerId)
            setCustomerField(customer ?? null);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        emailTemplateService.getAll().then(async (response) => {
            const taskCreationTemplate = response.find((template: any) => template.name === "Task creation");
            if (taskCreationTemplate) {
                setEmailTemplate(taskCreationTemplate);
                setTaskTemplate(await emailTemplateService.getById(taskCreationTemplate.id));
            } else {
                console.error("Task Creation template not found");
            }
        });
    }, []);

    async function createEmailArray(taskTemplate: any, personalEmail: any, allContacts: any, mainContactEmail: any) {
        let emails = [];

        if (taskTemplate.taskCreator) {
            emails.push(currentUser?.identity.email);
        }
        if (taskTemplate.adminEmail) {
            emails.push(adminEmail);
        }
        if (taskTemplate.personnel) {
           emails.push(personalEmail);
        }
        if (taskTemplate.customerContact === "Main Contact") {
            emails.push(mainContactEmail);
        }
        if (taskTemplate.customerContact === "All Contacts") {
            if (allContacts) {
                allContacts.map((obj: any) => emails.push(obj.email));
            };
         }
        return emails;
    }
    const emailBody = emailTemplate.body;
    const emailSubject = emailTemplate.subject;

    if (customerField === undefined)
        return <></>;

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose} size={visibleRecurring ? "xl" : undefined}>
            <Formik
                initialValues={{
                    customerId: customerId,
                    productId: "00000000-0000-0000-0000-000000000000",
                    taskTypeId: "",
                    userId: currentUser?.identity.id,
                    creatorId: "",
                    name: "",
                    location: location ?? "",
                    completedDate: null,
                    creationDate: dayjs(new Date()).format("YYYY-MM-DD"),
                    startTime: dayjs(getRoundedDate(30, new Date())).format("HH:mm"),
                    dueDate: dayjs(new Date()).format("YYYY-MM-DD"),
                    endTime: calculateEndTime(dayjs(getRoundedDate(30, new Date())).format("HH:mm")),
                    originalDueDate: dayjs(new Date()).format("YYYY-MM-DD"),
                    notes: "",
                    days: [],
                    recursiveInterval: undefined,
                    recursiveDuration: undefined,
                    commenceDate: undefined,
                    addToCalendar: false,
                    dayOfMonth: undefined,
                    yearlyDate: undefined
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    setSubmitting(false);

                    const cleanCustomerName = (customerLabel: string | undefined) => {
                        return customerLabel ? customerLabel.replace(/C\d{8}\s*/, "").trim() : "Customer Name";
                    };

                    const taskName = values.name
                        ? values.name
                        : `${allTaskTypes?.find((type) => type.value === values.taskTypeId)?.label || "Task Type"} - ${cleanCustomerName(allCustomers?.find((customer) => customer.value === values.customerId)?.label)}`;

                    const newTask: ITask = {
                        id: undefined,
                        customerId: values.customerId ?? "6d17eada-9e12-11ef-97de-88665a43d3c8",
                        productId: values.productId,
                        taskTypeId: values.taskTypeId,
                        userId: values.userId ?? "",
                        creatorId: currentUser ? currentUser.identity.id : "",
                        name: taskName,
                        location: values.location,
                        creationDate: visibleRecurring ? dayjs.utc(dayjs(new Date()).format("YYYY-MM-DD") + " " + values.startTime).toDate() : dayjs.utc(values.creationDate + " " + values.startTime).toDate(),
                        dueDate: visibleRecurring ? dayjs.utc(dayjs(new Date()).format("YYYY-MM-DD") + " " + values.endTime).toDate() : dayjs.utc(values.dueDate + " " + values.endTime).toDate(),
                        completedDate: undefined,
                        originalDueDate: dayjs.utc(values.dueDate + " " + values.endTime).toDate(),
                        notes: values.notes,
                        days: values.days.join(","),
                        recursiveInterval: values.recursiveInterval,
                        recursiveDuration: values.recursiveDuration,
                        commenceDate: values.commenceDate,
                        addToCalendar: values.addToCalendar,
                        dayOfMonth: values.dayOfMonth,
                        yearlyDate: values.yearlyDate

                    };
                    taskService.add(newTask).then(
                        async (response) => {
                            console.log(response);
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                let allContacts = await customerService.getAllContacts(newTask.customerId);
                                const mainContactEmail = allContacts.find((contact: any) => contact.isPrimaryContact)?.emailAddress;
                                const contacts = allContacts.map((item: any) => ({
                                    email: item.emailAddress,
                                  }));
                                    
                                const personal = allUsers?.find((item: any) => item.value === newTask.userId)?.label;
                                const personalEmail = personal.split(": ")[1];

                                onClose(true);
                                if (newTask.addToCalendar) {
                                    addTaskToCalender(response.id);
                                }
                                const receiverEmails = await createEmailArray(taskTemplate, personalEmail, contacts, mainContactEmail);
                                const updatedBody = await mapEmailBody({ body: emailBody, id: response.id, actioner: actioner });
                                const updatedSubject = await mapEmailBody({ body: emailSubject, id: response.id, actioner: actioner });
                                emailTemplateService.sendAutoEmail(updatedSubject, updatedBody, receiverEmails).then(
                                    (response) => {
                                        setStatus(response.message);
                                    });
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                                setFieldError("customerId", error.errors.CustomerId);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );

                }}
            >
                {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>New Task</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="w-100">
                            <div className="d-flex">
                                <div className="w-100">
                                    {!fromLead && (
                                        <Form.Group as={Row} className="mb-3" controlId="taskCustomerId">
                                            <Form.Label column sm={3}>
                                                Customer
                                            </Form.Label>
                                            <Col sm={9}>
                                                <Select
                                                    styles={ReactSelectBootstrapStyle}
                                                    className="stateManagedSelect"
                                                    onChange={(value) => setFieldValue("customerId", value == null ? "" : value.value)}
                                                    defaultInputValue={customerField?.label ?? undefined}
                                                    isClearable
                                                    options={allCustomers}
                                                />
                                                <ErrorMessage name="customerId" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                    )}

                                    <Form.Group as={Row} className="mb-3" controlId="taskProductId">
                                        <Form.Label column sm={3}>
                                            Product
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => setFieldValue("productId", value == null ? "00000000-0000-0000-0000-000000000000" : value.value)}
                                                isClearable
                                                options={allProducts}
                                            />
                                            <ErrorMessage name="productId" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="taskUserId">
                                        <Form.Label column sm={3}>
                                            Personnel
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => setFieldValue("userId", value == null ? "" : value.value)}
                                                defaultInputValue={currentUser?.identity.displayName + ": "+currentUser?.identity.email}
                                                isClearable
                                                options={allUsers}
                                            />
                                            <ErrorMessage name="userId" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="taskTaskName">
                                        <Form.Label column sm={3}>
                                            Task Name
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="name" type="text" className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")} />
                                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="taskTaskTypeId">
                                        <Form.Label column sm={3}>
                                            Type
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => setFieldValue("taskTypeId", value == null ? "" : value.value)}
                                                isClearable
                                                options={allTaskTypes}
                                            />
                                            <ErrorMessage name="taskTypeId" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="taskLocation">
                                        <Form.Label column sm={3}>
                                            Location
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="location" type="text" className={"form-control" + (errors.location && touched.location ? " is-invalid" : "")} />
                                            <ErrorMessage name="location" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    {
                                        !visibleRecurring &&
                                        <>
                                            <Form.Group as={Row} className="mb-3" controlId="taskCreationDate">
                                                <Form.Label column sm={3}>
                                                    Start Time
                                                </Form.Label>
                                                <Col sm={4}>
                                                    <Field name="creationDate" type="date" className={"form-control" + (errors.creationDate && touched.creationDate ? " is-invalid" : "")} />
                                                    <ErrorMessage name="creationDate" component="div" className="invalid-feedback" />
                                                </Col>
                                                <Col sm={4}>
                                                    <Field name="startTime" type="time" className={"form-control" + (errors.startTime && touched.startTime ? " is-invalid" : "")} />
                                                    <ErrorMessage name="startTime" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="taskDueDate">
                                                <Form.Label column sm={3}>
                                                    Due/End Time
                                                </Form.Label>
                                                <Col sm={4}>
                                                    <Field name="dueDate" type="date" className={"form-control" + (errors.dueDate && touched.dueDate ? " is-invalid" : "")} />
                                                    <ErrorMessage name="dueDate" component="div" className="invalid-feedback" />
                                                </Col>
                                                <Col sm={4}>
                                                    <Field name="endTime" type="time" className={"form-control" + (errors.endTime && touched.endTime ? " is-invalid" : "")} />
                                                    <ErrorMessage name="endTime" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Form.Group>

                                        </>

                                    }
                                    <Form.Group as={Row} className="mb-3" controlId="taskNotes">
                                        <Form.Label column sm={3}>
                                            Notes
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control as="textarea" name="notes"
                                                onChange={handleChange}
                                                className={"form-control" + (errors.notes && touched.notes ? " is-invalid" : "")} />
                                            <ErrorMessage name="notes" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="taskAddToCalendar">
                                        <Form.Label column sm={3}>
                                            Add to Calendar
                                        </Form.Label>
                                        <Col sm={9}>
                                            <FormCheck
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                checked={values.addToCalendar}
                                                onChange={handleChange}
                                                name="addToCalendar"
                                            />
                                        </Col>
                                    </Form.Group>
                                </div>
                                {visibleRecurring &&
                                    <>
                                        <div className="vr mx-4">
                                        </div>
                                    <RecurringAddTask
                                        values={values}
                                        errors={errors}
                                        handleChange={handleChange}
                                        touched={touched}
                                            show={true}
                                        />
                                    </>
                                }

                            </div>
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="d-flex justify-content-between align-items-center w-100">
                                <div className="form-group">
                                    <Button variant="secondary" className="me-2" onClick={() =>
                                        setVisibleRecurring(!visibleRecurring)

                                    }>
                                        {visibleRecurring ? "Single Task" : "Recurring Task"}
                                    </Button>
                                </div>
                                <div className="form-group">
                                    <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                        {isSubmitting ? <LoadingSpinner text="Creating task..." /> : "Create Task"}
                                    </Button>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </Modal.Footer>

                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { TaskAddModel };
