import { ErrorMessage, Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { Alert, Breadcrumb, Button, Col, Container, Form, FormCheck, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { IAddress } from "../../customTypings/Address";
import { IContact } from "../../customTypings/Contact";
import { IContactFull } from "../../customTypings/ContactFull";
import { IEndlessPoolsOrderForm } from "../../customTypings/EndlessPoolsOrderForm";
import { ILead } from "../../customTypings/Lead";
import {
    accessoriesService,
    addressService, companyService, contactService,
    customerService, emailTemplateService, formService, leadService, orderService, taskService, userService
} from "../../services";
import { ReactSelectBootstrapStyle } from "../../styles/ReactSelectBootstrapStyle";
import getCustomerEmail from "./Customer";
import mapEmailBody from "./Mapping";
function EndlessPoolsOrderForm() {

    const [alertVariant, setAlertVariant] = useState("danger");

    const [allCustomers, setAllCustomers] = useState<any[]>();
    const [selectedCustomerId, setSelectedCustomerId] = useState("");
    const [selectedCustomer, setSelectedCustomer] = useState<any>({});

    const [allUsers, setAllUsers] = useState<any[]>();
    const [selectedUserId, setSelectedUserId] = useState("");
    const [selectedUser, setSelectedUser] = useState<any>({});

    const [allSelectableTasks, setAllSelectableTasks] = useState<any[]>();
    const [selectedTaskId, setSelectedTaskId] = useState("");
    const [selectedTask, setSelectedTask] = useState<any>({});

    const [allCustomerContacts, setAllCustomerContacts] = useState<IContactFull[]>();
    const [selectedContact, setSelectedContact] = useState<any>({});

    const [company, setCompany] = useState<any>({});
    const [allCompanyAddresses, setAllCompanyAddresses] = useState<any[]>();
    const [allAccessories, setAllAcessories] = useState<any[]>();

    const [emailTemplate, setEmailTemplate] = useState<any>({});

    useEffect(() => {
        Promise.all([orderService.getAll(), customerService.getAllListItems(), userService.getAllListItems(), taskService.getAll(), taskService.getAllListItems(), accessoriesService.getAll()]).then((response) => {
            setAllCustomers(response[1]);
            setAllUsers(response[2]);
            setAllSelectableTasks(response[4]);
            setAllAcessories(response[5]);
        });
    }, []);

    useEffect(() => {
        getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserId]);

    useEffect(() => {
        getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allCustomers]);

    useEffect(() => {
        if (selectedCustomerId !== "create-new") {
            getCustomer();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomerId]);

    useEffect(() => {
        if (selectedCustomer && selectedCustomerId) {
            customerService.getAllContacts(selectedCustomerId).then((fullcustomerContacts) => {
                setAllCustomerContacts(fullcustomerContacts);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomer]);

    useEffect(() => {
        getTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTaskId]);

    useEffect(() => {
        getCompany();
    }, []);

    useEffect(() => {
        if (company) {
            const selectableCompanyAddresses = [
                {
                    label: `${company.line1}, ${company.line2}, ${company.line3}, ${company.town}, ${company.county}, ${company.postcode}`,
                    value: company.id,
                },
            ];
            setAllCompanyAddresses(selectableCompanyAddresses);
        }
    }, [company])

    useEffect(() => {
        if (allCustomerContacts && selectedCustomerId !== "create-new")
            setSelectedContact(allCustomerContacts.find((contact: any) => contact.isPrimaryContact));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allCustomerContacts]);

    useEffect(() => {
        emailTemplateService.getAll().then((response) => {
            const endlessPoolsOrderFormForSigningTemplate = response.find((template: any) => template.name === "Endless Pools Order Form For Signing");
            if (endlessPoolsOrderFormForSigningTemplate) {
                setEmailTemplate(endlessPoolsOrderFormForSigningTemplate);
            } else {
                console.error("Endless Pools Order Form template for Signing not found");
            }
        });
    }, []);
    function getCustomer() {
        if (selectedCustomerId) {
            customerService.getFull(selectedCustomerId).then((customer) => {
                setSelectedCustomer(customer);
            });
        }
    }
    function getTask() {
        if (selectedTaskId) {
            taskService.get(selectedTaskId).then((task) => {
                setSelectedTask(task);
            });
        }
    }
    function getUser() {
        if (allUsers && selectedUserId) {
            setSelectedUser(allUsers.find(item => item.value === selectedUserId));
        };
    }

    function getCompany() {
        setCompany(companyService.getCompany().then((response) => {
            setCompany(response);
        }));
    }

    function getCustomers() {
        allCustomers?.push({
            "value": "create-new",
            "label": "Create New Customer"
        });
    }

    const navigate = useNavigate();
    const emailBody = emailTemplate.body;

    const initialValues: any = {
        id: "",
        taskId: "",
        signingURL: "",
        orderDate: null,
        orderNumber: "",
        orderLocation: "",
        cofsent: false,
        cofLink: "",
        subtotal: "",
        orderTotal: "",
        depositAmount: "",
        depositMethods: [],
        balance: "",
        fundingChoice: "",
        partExchangeValueToBeDeducted: "",
        salesPersonName: "",
        salesPersonEmail: "",
        comments: "",
        customerName: "",
        customerContactTitle: "",
        customerContactName: "",
        customerAddress: "",
        customerHomeTel: "",
        customerMobileTel: "",
        customerEmail: "",
        customerReferredBy: [],
        productModel: "",
        productShellColour: "",
        productCabinet: "",
        productPrice: "",
        modelToBePartExchanged: "",
        features: [],
        coverOptions: [],
        accessoryList: [],
        servicePlan: [],
        deliveryMethod: "",
        electricalSupply: "",
        electricianRequired: false,
        preferredDeliveryDate: null,
        accessDescription: "",
        siteVisitFormSent: false,
        name: "",
        sentTime: null,
        completedTime: null,
    };

    const save = async (
        values: any,
        setStatus: any,
        setSubmitting: any,
        setFieldError: any,
        resetForm: any
    ) => {
        const updatedEndlessPoolsOrderForm: IEndlessPoolsOrderForm = {
            id: undefined,
            taskId: values.taskId,
            signingURL: undefined,
            taskName: selectedTask.name,
            taskURL: undefined,
            orderDate: values.orderDate,
            orderNumber: values.orderNumber,
            orderLocation: values.orderLocation,
            cofsent: values.cofsent,
            cofLink: values.cofLink,
            subtotal: values.subtotal,
            orderTotal: values.orderTotal,
            depositAmount: values.depositAmount,
            depositMethods: values.depositMethods.join(","),
            balance: values.balance,
            fundingChoice: values.fundingChoice,
            partExchangeValueToBeDeducted: values.partExchangeValueToBeDeducted,
            salesPersonName: selectedUser.label,
            salesPersonEmail: values.salesPersonEmail,
            comments: values.comments,
            customerName: selectedContact.id ? selectedContact.name : `${values.firstName} ${values.lastName}`,
            customerContactTitle: selectedContact.id ? "" : values.customerContactTitle,
            customerContactName: selectedContact.id ? selectedContact.name : `${values.firstName} ${values.lastName}`,
            customerAddress: selectedContact.id ? `${selectedContact.address.line1}, ${selectedContact.address.line2}, ${selectedContact.address.line3}, ${selectedContact.address.town}, ${selectedContact.address.country}, ${selectedContact.address.postcode}` : `${values.addressLine1}, ${values.addressLine2}, ${values.town}, ${values.county}, ${values.country}, ${values.postalCode}`,
            customerHomeTel: selectedContact.id ? selectedContact.phoneNumber : values.homeTelephone,
            customerMobileTel: selectedContact.id ? selectedContact.phoneNumber : values.mobileTelephone,
            customerEmail: selectedContact.id ? selectedContact.emailAddress : values.emailAddress,
            customerReferredBy: values.customerReferredBy.join(","),
            productModel: values.productModel,
            productShellColour: values.productShellColour,
            productCabinet: values.productShellColour,
            productPrice: values.productPrice,
            modelToBePartExchanged: values.modelToBePartExchanged,
            features: values.features.join(","),
            coverOptions: values.coverOptions.join(","),
            accessoryList: values.accessoryList.join(","),
            servicePlan: values.servicePlan.join(","),
            deliveryMethod: values.deliveryMethod,
            electricalSupply: values.electricalSupply,
            electricianRequired: values.electricianRequired,
            preferredDeliveryDate: values.preferredDeliveryDate,
            accessDescription: values.accessDescription,
            siteVisitFormSent: values.siteVisitFormSent,
            name: "Endless Pools Order Form",
            sentTime: new Date(),
            completedTime: undefined,
        };

        if (selectedCustomerId === "create-new") {
            const updatedContact: IContact = {
                id: undefined,
                name: `${values.firstName} ${values.lastName}`,
                emailAddress: values.emailAddress,
                mobilePhone: values.mobileTelephone,
            };
            const updatedAddress: IAddress = {
                id: undefined,
                line1: values.addressLine1,
                line2: values.addressLine2,
                line3: values.line3,
                town: values.town,
                county: values.country,
                postcode: values.postalCode,
                what3words: "null",
                latitude: "",
                longitude: "",
            };
            const contact = await contactService.add(updatedContact);
            const address = await addressService.add(updatedAddress);

            const updatedLead: ILead = {
                id: undefined,
                leadNumber: "",
                name: `${values.firstName} ${values.lastName}`,
                isBusiness: true,
                dateOfInquiry: undefined,
                notes: "",
                contactId: contact.id,
                addressId: address.id,
                department: "Sales",
            };

            leadService.add(updatedLead);

        }

        formService.addEndlessPoolsOrderForm(updatedEndlessPoolsOrderForm).then(
            async (response) => {
                setSubmitting(false);
                if (response.status === "Success") {
                    resetForm({ values: "" });
                    setAlertVariant("success");
                    setStatus("Endless Pools Order Form has been submitted successfully,You will receive a confirmational email shortly");
                    const updatedBody = await mapEmailBody({ body: emailBody, id: response.id });
                    const receiverEmail = await getCustomerEmail({ id: response.id });
                    emailTemplateService.sendAutoEmail(emailTemplate.subject, updatedBody, [receiverEmail]).then(
                        (response) => {
                            setStatus(response.message);
                        });
                    setTimeout(() => {
                        navigate("/formListPage");
                    }, 2000);
                } else {
                    setAlertVariant("danger");
                    setStatus(response.message);
                }
            },
            (error) => {
                setAlertVariant("danger");
                setStatus(error.title);
            }
        );
    };
    const paymentMethods = ['Cash', 'Cheque', 'Card', 'BACS']
    const fundingChoices = ['Full payment prior to delivery', 'Finance terms']
    const customerReferences = ["Google reviews/internet search", "Happyhottubs.co.uk", "Recommendation", "Radio", "Social media", "Local adverts / Vans", "Show", "Other"]
    const features = ["Bluetooth speaker system", "Treadmill"]
    const coverOptions = ["Hard top cover (Black)  £1249", "Uprite lifter x2  £1198", "Cover Cradle x2  £1198", "End 2 end roll on cover  £4999", "Own Brand Roll on cover  £1999", "Cavana  (depending on size) £13995-£21995"]
    const accessories = allAccessories?.map(accessory => accessory.name);
    const servicePlans = ["EP Wellness Plan", "EP Wellness PLUS Plan"]
    const deliveryMethods = ["Manual delivery & commision (FREE)", "Crane/hiab delivery and commission - POA"]
    const electricalSupplies = ["32 amp", "24 amp + 32 amp", "2x 32 amp"]


    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <Helmet>
                            <title>Endless pools Order Form</title>
                        </Helmet>

                        <Breadcrumb>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/formListPage" }}>
                                Forms
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Endless pools Order Form</Breadcrumb.Item>
                        </Breadcrumb>

                        <h1 className="my-3">Endless pools Order Form</h1>
                        <Formik
                            initialValues={{ ...initialValues }}
                            enableReinitialize
                            onSubmit={(
                                values,
                                { setStatus, setSubmitting, setFieldError, resetForm }
                            ) => {
                                save(values, setStatus, setSubmitting, setFieldError, resetForm);
                            }}
                        >
                            {({
                                errors,
                                status,
                                touched,
                                isSubmitting,
                                handleSubmit,
                                values,
                                setStatus,
                                handleChange,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group as={Row} className="mb-3" controlId="orderDate">
                                        <Form.Label column sm={3}>
                                            Date the order is placed
                                        </Form.Label>
                                        <Col sm={4}>
                                            <Field name="orderDate" type="datetime-local" className={"form-control" + (errors.orderDate && touched.orderDate ? " is-invalid" : "")} />
                                            <ErrorMessage name="orderDate" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="orderNumber">
                                        <Form.Label column sm={3}>
                                            Order Number
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="orderNumber" type="text" className={"form-control" + (errors.orderNumber && touched.orderNumber ? " is-invalid" : "")} />
                                            <ErrorMessage name="orderNumber" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="taskId">
                                        <Form.Label column sm={3}>
                                            Associated Task
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setSelectedTaskId(value.value);
                                                    setFieldValue("taskId", value == null ? "" : value.value);
                                                }}
                                                required
                                                options={allSelectableTasks}
                                            />
                                            <ErrorMessage name="taskId" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="orderLocation">
                                        <Form.Label column sm={3}>
                                            Order Location
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setFieldValue("orderLocation", value == null ? "" : value.label);
                                                }}
                                                isClearable
                                                options={allCompanyAddresses}
                                            />
                                            <ErrorMessage name="orderLocation" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="cofsent">
                                        <Form.Label column sm={3}>
                                            COF Sent?
                                        </Form.Label>
                                        <Col sm={9}>
                                            <FormCheck
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                checked={values.cofsent}
                                                onChange={handleChange}
                                                name="cofsent"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="cofLink">
                                        <Form.Label column sm={3}>
                                            COF Link
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="cofLink" type="text" className={"form-control" + (errors.cofLink && touched.cofLink ? " is-invalid" : "")} />
                                            <ErrorMessage name="cofLink" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="subtotal">
                                        <Form.Label column sm={3}>
                                            Sub Total
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="subtotal" placeholder="£" type="number" className={"form-control" + (errors.subtotal && touched.subtotal ? " is-invalid" : "")} />
                                            <ErrorMessage name="subtotal" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="orderTotal">
                                        <Form.Label column sm={3}>
                                            Order Total
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="orderTotal" placeholder="£" type="number" className={"form-control" + (errors.orderTotal && touched.orderTotal ? " is-invalid" : "")} />
                                            <ErrorMessage name="orderTotal" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="depositAmount">
                                        <Form.Label column sm={3}>
                                            Deposit Amount
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="depositAmount" placeholder="£" type="number" className={"form-control" + (errors.depositAmount && touched.depositAmount ? " is-invalid" : "")} />
                                            <ErrorMessage name="depositAmount" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="depositMethods">
                                        <Form.Label column sm={3}>Deposit Methods(s)</Form.Label>
                                        <Col sm={9} className="pt-2">
                                            {paymentMethods.map((method, index) =>
                                                <label key={index} className="d-inline me-3">
                                                    <Field type="checkbox" name="depositMethods" value={method} className="me-2"
                                                    />
                                                    {method}
                                                </label>
                                            )}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="balance">
                                        <Form.Label column sm={3}>
                                            Balance after deposit
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="balance" placeholder="£" type="number" className={"form-control" + (errors.balance && touched.balance ? " is-invalid" : "")} />
                                            <ErrorMessage name="balance" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="fundingChoice">
                                        <Form.Label column sm={3}>Funding choice</Form.Label>
                                        <Col sm={9} className="pt-2">
                                            {fundingChoices.map((choice, index) =>
                                                <label key={index} className="d-inline me-3">
                                                    <Field type="radio" name="fundingChoice" value={choice} className="me-2"
                                                    />
                                                    {choice}
                                                </label>
                                            )}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="partExchangeValueToBeDeducted">
                                        <Form.Label column sm={3}>
                                            Part exchange value to be deducted
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="partExchangeValueToBeDeducted" placeholder="£" type="number" className={"form-control" + (errors.partExchangeValueToBeDeducted && touched.partExchangeValueToBeDeducted ? " is-invalid" : "")} />
                                            <ErrorMessage name="partExchangeValueToBeDeducted" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="salesPersonName">
                                        <Form.Label column sm={3}>
                                            Sales person Name
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setSelectedUserId(value ? value.value : "");
                                                    setFieldValue("salesPersonName", value ? value.label : "");
                                                }}
                                                isClearable
                                                options={allUsers}
                                            />
                                            <ErrorMessage name="salesPersonName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="salesPersonEmail">
                                        <Form.Label column sm={3}>
                                            Sales person Email
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="salesPersonEmail" type="text" className={"form-control" + (errors.salesPersonEmail && touched.salesPersonEmail ? " is-invalid" : "")} />
                                            <ErrorMessage name="salesPersonEmail" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="comments">
                                        <Form.Label column sm={3}>
                                            General comments about the sale
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control as="textarea" name="comments"
                                                onChange={handleChange}
                                                className={"form-control" + (errors.comments && touched.comments ? " is-invalid" : "")} />
                                            <ErrorMessage name="comments" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <h3>CUSTOMER DETAILS</h3>

                                    <Form.Group as={Row} className="mb-3" controlId="customerName">
                                        <Form.Label column sm={3}>
                                            Select a customer or Create a new one
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className="stateManagedSelect"
                                                onChange={(value) => {
                                                    setSelectedCustomerId(value.value);
                                                    setFieldValue("customerName", value == null ? "" : value.value);
                                                }}
                                                required
                                                options={allCustomers}
                                            />
                                            <ErrorMessage name="customerName" component="div" className="invalid-feedback" />

                                        </Col>
                                    </Form.Group>
                                    {selectedCustomerId === "create-new" && (
                                        <div>
                                            <Form.Group as={Row} className="mb-3" controlId="customerContactTitle">
                                                <Form.Label column sm={3}>
                                                    Title
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Select
                                                        styles={ReactSelectBootstrapStyle}
                                                        className="stateManagedSelect"
                                                        onChange={(value) => {
                                                            setFieldValue("customerContactTitle", value === null ? "" : value.value);
                                                        }}
                                                        isClearable
                                                        options={['Mr', 'Mrs', 'Mx', 'Miss', 'Dr', 'Prof'].map((method) => ({ label: method, value: method }))}
                                                    />
                                                    <ErrorMessage name="customerContactTitle" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="firstName">
                                                <Form.Label column sm={3}>
                                                    First Name
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Field name="firstName" type="text" className={"form-control" + (errors.firstName && touched.firstName ? " is-invalid" : "")} />
                                                    <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="lastName">
                                                <Form.Label column sm={3}>
                                                    Last Name
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Field name="lastName" type="text" className={"form-control" + (errors.lastName && touched.lastName ? " is-invalid" : "")} />
                                                    <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="postalCode">
                                                <Form.Label column sm={3}>
                                                    Post Code
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Field name="postalCode" type="text" className={"form-control" + (errors.postalCode && touched.postalCode ? " is-invalid" : "")} />
                                                    <ErrorMessage name="postalCode" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="addressLine1">
                                                <Form.Label column sm={3}>
                                                    Address Line 1
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Field name="addressLine1" type="text" className={"form-control" + (errors.addressLine1 && touched.addressLine1 ? " is-invalid" : "")} />
                                                    <ErrorMessage name="addressLine1" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="addressLine2">
                                                <Form.Label column sm={3}>
                                                    Address Line 2
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Col sm={9}>
                                                        <Field name="addressLine2" type="text" className={"form-control" + (errors.addressLine2 && touched.addressLine2 ? " is-invalid" : "")} />
                                                        <ErrorMessage name="addressLine2" component="div" className="invalid-feedback" />
                                                    </Col>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="town">
                                                <Form.Label column sm={3}>
                                                    Town/City
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Field name="town" type="text" className={"form-control" + (errors.town && touched.town ? " is-invalid" : "")} />
                                                    <ErrorMessage name="town" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="county">
                                                <Form.Label column sm={3}>
                                                    County
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Field name="county" type="text" className={"form-control" + (errors.salesPersonEmail && touched.salesPersonEmail ? " is-invalid" : "")} />
                                                    <ErrorMessage name="county" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="country">
                                                <Form.Label column sm={3}>
                                                    Country
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Field name="country" type="text" className={"form-control" + (errors.country && touched.country ? " is-invalid" : "")} />
                                                    <ErrorMessage name="country" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="emailAddress">
                                                <Form.Label column sm={3}>
                                                    Email Address
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Field name="emailAddress" type="text" required className={"form-control" + (errors.emailAddress && touched.emailAddress ? " is-invalid" : "")} />
                                                    <ErrorMessage name="emailAddress" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="homeTelephone">
                                                <Form.Label column sm={3}>
                                                    Home Telephone
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Field name="homeTelephone" type="text" className={"form-control" + (errors.homeTelephone && touched.homeTelephone ? " is-invalid" : "")} />
                                                    <ErrorMessage name="homeTelephone" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="mobileTelephone">
                                                <Form.Label column sm={3}>
                                                    Mobile Telephone
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Field name="mobileTelephone" type="text" className={"form-control" + (errors.mobileTelephone && touched.mobileTelephone ? " is-invalid" : "")} />
                                                    <ErrorMessage name="mobileTelephone" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Form.Group>
                                        </div>
                                    )}
                                    <Form.Group as={Row} className="mb-3" controlId="customerReferredBy">
                                        <Form.Label column sm={3}>How did the customer hear about us?</Form.Label>
                                        <Col sm={6} className="pt-2">
                                            <Row>
                                                {customerReferences.map((method, index) =>
                                                    <Col key={index} sm={6} className="mb-2">
                                                        <label className="d-inline me-3">
                                                            <Field type="checkbox" name="customerReferredBy" value={method} className="me-2" />
                                                            {method}
                                                        </label>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Form.Group>

                                    <h3>PRODUCT</h3>
                                    <Form.Group as={Row} className="mb-3" controlId="productModel">
                                        <Form.Label column sm={3}>
                                            Product Model
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="productModel" type="text" className={"form-control" + (errors.productModel && touched.productModel ? " is-invalid" : "")} />
                                            <ErrorMessage name="productModel" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="productShellColour">
                                        <Form.Label column sm={3}>
                                            Product Shell Colour
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="productShellColour" type="text" className={"form-control" + (errors.productShellColour && touched.productShellColour ? " is-invalid" : "")} />
                                            <ErrorMessage name="productShellColour" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="productCabinet">
                                        <Form.Label column sm={3}>
                                            Product Cabinet
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="productCabinet" type="text" className={"form-control" + (errors.productCabinet && touched.productCabinet ? " is-invalid" : "")} />
                                            <ErrorMessage name="productCabinet" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="productPrice">
                                        <Form.Label column sm={3}>
                                            Product Price
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="productPrice" type="text" className={"form-control" + (errors.productPrice && touched.productPrice ? " is-invalid" : "")} />
                                            <ErrorMessage name="productPrice" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="modelToBePartExchanged">
                                        <Form.Label column sm={3}>
                                            Model To Be Part Exchanged
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Field name="modelToBePartExchanged" type="text" className={"form-control" + (errors.modelToBePartExchanged && touched.modelToBePartExchanged ? " is-invalid" : "")} />
                                            <ErrorMessage name="modelToBePartExchanged" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>

                                    <h4>FEATURES</h4>

                                    <Form.Group as={Row} className="mb-3" controlId="features">
                                        <Form.Label column sm={3}></Form.Label>
                                        <Col sm={12} className="pt-2">
                                            {features.map((pkg, index) =>
                                                <label key={index} className="d-block">
                                                    <Field type="checkbox" name="features" value={pkg} className="me-2"
                                                    />
                                                    {pkg}
                                                </label>
                                            )}
                                        </Col>
                                    </Form.Group>
                                    <h4>COVER OPTIONS</h4>
                                    <Form.Group as={Row} className="mb-3" controlId="coverOptions">
                                        <Form.Label column sm={3}></Form.Label>
                                        <Col sm={12} className="pt-2">
                                            {coverOptions.map((pkg, index) =>
                                                <label key={index} className="d-block">
                                                    <Field type="checkbox" name="coverOptions" value={pkg} className="me-2"
                                                    />
                                                    {pkg}
                                                </label>
                                            )}
                                        </Col>
                                    </Form.Group>
                                    <h4>ACCESSORIES</h4>
                                    <Form.Group as={Row} className="mb-3" controlId="accessoryList">
                                        <Form.Label column sm={3}></Form.Label>
                                        <Col sm={12} className="pt-2">
                                            {accessories?.map((acc, index) =>
                                                <label key={index} className="d-block">
                                                    <Field type="checkbox" name="accessoryList" value={acc} className="me-2"
                                                    />
                                                    {acc}
                                                </label>
                                            )}
                                        </Col>
                                    </Form.Group>
                                    <h4>SERVICING OPTIONS</h4>
                                    <Form.Group as={Row} className="mb-3" controlId="servicePlan">
                                        <Form.Label column sm={3}></Form.Label>
                                        <Col sm={12} className="pt-2">
                                            {servicePlans?.map((acc, index) =>
                                                <label key={index} className="d-block">
                                                    <Field type="checkbox" name="servicePlan" value={acc} className="me-2"
                                                    />
                                                    {acc}
                                                </label>
                                            )}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="deliveryMethod">
                                        <Form.Label column sm={3}>Delivery Method</Form.Label>
                                        <Col sm={9} className="pt-2">
                                            {deliveryMethods.map((choice, index) =>
                                                <label key={index} className="d-inline me-3">
                                                    <Field type="radio" name="deliveryMethod" value={choice} className="me-2"
                                                    />
                                                    {choice}
                                                </label>
                                            )}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="electricalSupply">
                                        <Form.Label column sm={3}>Electrical Supply</Form.Label>
                                        <Col sm={9} className="pt-2">
                                            {electricalSupplies.map((choice, index) =>
                                                <label key={index} className="d-inline me-3">
                                                    <Field type="radio" name="electricalSupply" value={choice} className="me-2"
                                                    />
                                                    {choice}
                                                </label>
                                            )}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="electricianRequired">
                                        <Form.Label column sm={3}>
                                            Electrician required?
                                        </Form.Label>
                                        <Col sm={9}>
                                            <FormCheck
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                checked={values.electricianRequired}
                                                onChange={handleChange}
                                                name="electricianRequired"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="preferredDeliveryDate">
                                        <Form.Label column sm={3}>
                                            Preferred Delivery Date
                                        </Form.Label>
                                        <Col sm={4}>
                                            <Field name="preferredDeliveryDate" type="datetime-local" className={"form-control" + (errors.preferredDeliveryDate && touched.preferredDeliveryDate ? " is-invalid" : "")} />
                                            <ErrorMessage name="preferredDeliveryDate" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="accessDescription">
                                        <Form.Label column sm={3}>
                                            Description of access to the site
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control as="textarea" name="accessDescription"
                                                onChange={handleChange}
                                                className={"form-control" + (errors.accessDescription && touched.accessDescription ? " is-invalid" : "")} />
                                            <ErrorMessage name="accessDescription" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="siteVisitFormSent">
                                        <Form.Label column sm={3}>
                                            Site Visit Form Sent?
                                        </Form.Label>
                                        <Col sm={9}>
                                            <FormCheck
                                                type="switch"
                                                id="custom-switch"
                                                label=""
                                                checked={values.siteVisitFormSent}
                                                onChange={handleChange}
                                                name="siteVisitFormSent"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Button
                                        variant="primary"
                                        disabled={isSubmitting}
                                        type="submit"
                                        className="me-2 d-flex align-items-end ms-auto"
                                    >
                                        {isSubmitting ? <LoadingSpinner text="Saving..." /> : "Complete"}
                                    </Button>

                                    {status && (
                                        <Alert variant={alertVariant} className="mt-3">
                                            {status}
                                        </Alert>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default EndlessPoolsOrderForm;