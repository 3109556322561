import dayjs from "dayjs";
import { Col, Container, Row, Table } from "react-bootstrap";
import { usePDF } from "react-to-pdf";
import "../../styles/form.css";

type Props = {
    currentForm: any;
};

const SiteVisitPdf: React.FC<Props> = ({ currentForm }) => {
    const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
    return (
        <div>
            <button onClick={() => toPDF()}>Download PDF</button>
            <div>
                <div>
                    <Container fluid="lg">
                        <Row ref={targetRef} className="download-pdf p-2 m-auto">
                            <Row>
                                <div className="d-flex">
                                    <img className="logo" src="/happy-hot-tubs-logo.svg" alt="" />
                                    <div className="heading" style={{ width: "120%" }}>
                                        <strong><h3 style={{ color: "#4A9CE8" }} >{currentForm?.name}</h3></strong>
                                        <p className="m-0" >Store acrosss the South and Midlands. Tel: 02380 812769</p>
                                        <strong>visit www.happyhottubs.co.uk</strong>
                                    </div>
                                </div>
                                <div className="border-dark border-top">
                                    <Row>
                                        <Col xs={3}>
                                            <strong>Name:</strong>
                                        </Col>
                                        <Col xs={9}>
                                            <p className="m-0" >{currentForm?.customerName}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={3}>
                                            <strong>Address:</strong>
                                        </Col>
                                        <Col xs={9}>
                                            <p className="m-0" >{currentForm?.customerAddress}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={3}>
                                            <strong>Telephone Nos:</strong>
                                        </Col>
                                        <Col xs={9}>
                                            <p className="m-0" >
                                                Home No:{currentForm?.customerHomeTel}, Mobile No: {currentForm?.customerMobileTel}, Work No: {currentForm?.customerWorkTel},
                                                Other:{currentForm?.customerOtherTel}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={3}>
                                            <strong>E-mail address:</strong>
                                        </Col>
                                        <Col xs={9}>
                                            <p className="m-0" >{currentForm?.customerEmail}</p>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <strong className="me-2">Visited Scheduled By:</strong>
                                    <p className="m-0" >{currentForm?.visitScheduledBy}</p>
                                </div>
                            </Row>
                            <Row>
                                <Col xs={9} className="border-dark border-top border-bottom">
                                    <Row className="border-dark border-bottom">
                                        <Row>
                                            <Col xs={3}>
                                                <strong>Spa make/model:</strong>
                                            </Col>
                                            <Col xs={9}>
                                                <div className="d-flex">
                                                    <p className="m-0" >{currentForm?.productMake} {currentForm?.productModel}</p>
                                                    <p className="m-0" ><strong className="ms-5">comments: </strong> {currentForm?.productComments}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={3}>
                                                <strong>Colour:</strong>
                                            </Col>
                                            <Col xs={9}>
                                                <p className="m-0" >{currentForm?.productColour}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={3}>
                                                <strong>Cabinet:</strong>
                                            </Col>
                                            <Col xs={9}>
                                                <p className="m-0" >{currentForm?.productCabinet}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={3}>
                                                <strong>Serial Number:</strong>
                                            </Col>
                                            <Col xs={9}>
                                                <p className="m-0" >{currentForm?.productSerialNumber}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={3}>
                                                <strong>Delivery Date:</strong>
                                            </Col>
                                            <Col xs={9}>
                                                <p className="m-0" >{currentForm?.productDeliveryDate}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={3}>
                                                <strong>Filter code:</strong>
                                            </Col>
                                            <Col xs={9}>
                                                <p className="m-0" >{currentForm?.productFilterCode}</p>
                                            </Col>
                                        </Row>
                                        <div className="d-flex gap-5">
                                            <div>
                                                <div className="d-flex gap-2">
                                                    <strong>Fresh Water: </strong>
                                                    <p className="m-0" >{currentForm?.productFreshWater ? "Yes" : "No"}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex gap-2">
                                                    <strong>Bromine: </strong>
                                                    <p className="m-0" >{currentForm?.productBromine ? "Yes" : "No"}</p>
                                                </div>
                                            </div>
                                            <strong>Warranty {currentForm?.productWarranty}</strong>
                                        </div>
                                    </Row>
                                    <div>
                                        <Row>
                                            <Col xs={3}>
                                                <strong>Visit Type:</strong>
                                            </Col>
                                            <Col xs={9}>
                                                <p className="m-0" >{currentForm?.visitType}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={3}>
                                                <strong>Visit Details:</strong>
                                            </Col>
                                            <Col xs={9}>
                                                <p className="m-0" >{currentForm?.visitDetails}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col xs={3} className="border-dark border py-2">
                                    <p className="text-decoration-underline text-success text-center m-0">
                                        SERVICE PLAN
                                    </p>
                                    <div className="d-flex justify-content-between">
                                        <strong>HPW O-Care:</strong>
                                        <p className="m-0" >{currentForm?.servicePlanHPWOCare ? "Yes" : "No"}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <strong>HPW Silk:</strong>
                                        <p className="m-0" >{currentForm?.servicePlanHPWSilk ? "Yes" : "No"}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <strong>No Pump O/SB:</strong>
                                        <p className="m-0" >{currentForm?.servicePlanNoPumpOSB ? "Yes" : "No"}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <strong>LC:</strong>
                                        <p className="m-0" >{currentForm?.servicePlanLC ? "Yes" : "No"}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <strong>LC Lite:</strong>
                                        <p className="m-0" >{currentForm?.servicePlanLCLite ? "Yes" : "No"}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <strong>LPC:</strong>
                                        <p className="m-0" >{currentForm?.servicePlanLPC ? "Yes" : "No"}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <strong>Gold:</strong>
                                        <p className="m-0" >{currentForm?.servicePlanGold ? "Yes" : "No"}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <strong>Ultimate:</strong>
                                        <p className="m-0" >{currentForm?.servicePlanUltimate ? "Yes" : "No"}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <strong>EP Wellness:</strong>
                                        <p className="m-0" >{currentForm?.servicePlanEPWellness ? "Yes" : "No"}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <strong>EP Wellness +:</strong>
                                        <p className="m-0" >{currentForm?.servicePlanEPWellnessPlus ? "Yes" : "No"}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pt-2 ">
                                <Table borderless className="border-dark border">
                                    <thead>
                                        <tr>
                                            <th className="border border-dark border-end-0">
                                                Description of Parts / Services / Chemicals / Accessories
                                            </th>
                                            <th className="border-bottom border-dark"></th>
                                            <th className="border border-dark">Qty</th>
                                            <th className="border border-dark">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[...Array(8)].map((_, index) => (
                                            <tr key={index}>
                                                <td></td>
                                                <td></td>
                                                <td className="border-dark border-start border-end"></td>
                                                <td></td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td></td>
                                            <td className="border border-dark text-center">
                                                <strong>Total</strong>
                                            </td>
                                            <td className="border border-dark text-center">
                                                <strong></strong>
                                            </td>
                                            <td className="border border-dark text-center">
                                                <strong></strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Row>
                            <Row>
                                <strong>Action Taken By Engineer & Notes</strong>
                                <div
                                    className="border border-dark"
                                    style={{ minHeight: "120px" }}
                                >{currentForm?.visitActionTakenByEngineerAndNotes}</div>
                            </Row>
                            <Row className="border-dark border-bottom py-2">
                                <Row>
                                    <Col xs={4}>
                                        <strong>Follow up visit required:</strong>
                                    </Col>
                                    <Col xs={4}>
                                        <p className="m-0" >Yes: {currentForm?.followUpVisitRequired ? "(state reason above)" : ""} </p>
                                    </Col>
                                    <Col xs={4}>
                                        <p className="m-0" >No: {currentForm?.followUpVisitRequired ? "" : "(state reason above)"}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4}>
                                        <strong>Date booked (if applicable):</strong>
                                    </Col>
                                    <Col xs={4}>
                                        <p className="m-0" >Yes,{currentForm?.visitDateBooked ? currentForm?.visitDateBooked : ""} </p>
                                    </Col>
                                    <Col xs={4}>
                                        <p className="m-0" >No {currentForm?.visitDateBooked ? "" : "(state reason above)"}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4}>
                                        <strong className="col-4">
                                            Payment received by (if applicable):
                                        </strong>
                                    </Col>
                                    <Col xs={4}>
                                        <p className="m-0" >{currentForm?.visitPaymentMethod}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4}>
                                        <strong>Name of engineer booked:</strong>
                                    </Col>
                                    <Col xs={4}>
                                        <p className="m-0" >{currentForm?.visitEngineerName}</p>
                                    </Col>
                                    <Col xs={4}>
                                        <div className="d-flex justify-content-between">
                                            <strong> Date of Visit:</strong>
                                            <p className="m-0" >{dayjs(currentForm?.visitDate).format("ddd DD MMM YYYY")}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Row>
                            <Row className="py-2">
                                <strong><h3 className="text-decoration-underline" style={{ fontFamily: "Arial, sans-serif" }}>Customer Section:</h3></strong>
                                <p className="m-0" style={{ fontFamily: "Arial, sans-serif" }}>
                                    By signing below you are confirming you are satisfied with your
                                    site visit, and the details entered by the engineer, unless you
                                    specify otherwise. If you have any concerns or comments then
                                    please write in the customer comments section below. Please note
                                    any agreements made with the engineer must be written on the front
                                    of this form, as we will not accept verbal agreements claimed at a
                                    later date. If you were out at the time of the visit, or have a
                                    complaint you didn't wish to put on the form, then please put any
                                    complaints/problems in writing within 48 hours.
                                </p>
                                <Row className="my-3">
                                    <Col xs={4}>
                                        <Row>
                                            <Col xs={6}>
                                                <p className="m-0" >Customer signature:</p>
                                            </Col>
                                            <Col xs={6}>
                                                <p className="m-0" ><img height={35} width={120} src={currentForm?.customerSignature} alt="signature" /></p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={4}>
                                        <Row>
                                            <Col xs={4} className="text-end">
                                                <p className="m-0" >Name:</p>
                                                <p className="m-0" >{currentForm?.customerFullName}</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p className="m-0" ></p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={4}>
                                        <Row>
                                            <Col xs={4} className="text-end">
                                                <p className="m-0" >Date:</p>
                                                <p className="m-0" >{currentForm?.completedTime}</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p className="m-0" ></p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div className="d-flex gap-2">
                                    <p className="m-0" >Customer comments:.................................................................................................................................................................................................................................
                                        ...................................................................................................................................................................................................................................................................
                                        ...................................................................................................................................................................................................................................................................</p>
                                    <p className="m-0" ></p>
                                </div>
                            </Row>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default SiteVisitPdf;