import { ITaskCategory } from "@/customTypings/TaskCategory";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { taskService } from "../services";
import HHTModal from "../Shared/HHTModal";
import {
    ColumnRender, FilterType, IColumn, IFilter, IPagedListAction
} from "./PagedList/Declarations";
import { PagedList } from "./PagedList/PagedList";
function AddTaskCategory() {
  const [showAddTask, setShowAddTask] = useState(false);
  const [refreshNumber, setRefeshNumber] = useState(0);
  const [showEditTask, setShowEditTask] = useState(false);
  const [taskCategory, setTaskCategory] = useState<ITaskCategory>();
  const columns: IColumn[] = [];
  const filters: IFilter[] = [];
  const headActions: IPagedListAction[] = [];
  const handleAddTask = (added: boolean) => {
    if (added) {
      setRefeshNumber(refreshNumber + 1);
    }
    setShowAddTask(false);
  };
  const handleOpenAddTask = () => {
    setShowAddTask(true);
  };
  columns.push({
    member: "name",
    renderAs: ColumnRender.text,
    title: "Category",
    width: 100,
  } as IColumn);

  filters.push({
    title: "Category name",
    member: "name",
    type: FilterType.text,
  } as IFilter);

  headActions.push({
    icon: faPlus,
    text: "Add Task Category",
    onClick: handleOpenAddTask,
  } as IPagedListAction);

  const addTaskCategory = async (values: any) => {
    const taskCategory: ITaskCategory = {
      id: undefined,
      name: values.name,
    };

    try {
      const res = await taskService.addTaskCategory(taskCategory);
      return res;
    } catch (error: any) {
      return { status: "Failure", message: error.title };
    }
  };

  const editTaskCategory = async (values: any) => {
    const updatedTaskCategory: ITaskCategory = {
      id: taskCategory?.id,
      name: values.name,
    };
    try {
      const res = await taskService.editTaskCategory(updatedTaskCategory);
      setRefeshNumber(refreshNumber + 1);
      return res;
    } catch (error: any) {
      return { status: "Failure", message: error.title };
    }
  };

  return (
    <>
      <Container fluid>
        <Helmet>
          <title>Task Types</title>
        </Helmet>
        <Row>
          <Col>
            <h1 className="my-3">Task Categories</h1>
            <PagedList
              itemActions={[
                {
                  icon: faPen,
                  text: "Edit",
                  onClick: (item) => {
                    setTaskCategory(item);
                    setShowEditTask(true);
                  },
                } as IPagedListAction,
              ]}
              columns={columns}
              headActions={headActions}
              filters={filters}
              defaultSortBy="name"
              getUrl="api/task/taskcategories"
              avatarUrl="api/avatar"
              rowFormatter={(item: any) => {
                return null;
              }}
              refreshNumber={refreshNumber}
            />
            <HHTModal
              callback={addTaskCategory}
              show={showAddTask}
              onClose={handleAddTask}
              title="Task Category"
              fields={[{ name: "name", type: "text" }]}
            />
          </Col>
        </Row>
      </Container>
      <HHTModal
        show={showEditTask}
        onClose={() => setShowEditTask(false)}
        callback={editTaskCategory}
        title="Task Type"
        fields={[{ name: "name", type: "text", value: taskCategory?.name }]}
      />
    </>
  );
}

export default AddTaskCategory;
