import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { ILeadProduct } from "../../customTypings/LeadProduct";
import { orderService, productService } from "../../services";
import { ReactSelectBootstrapStyle } from "../../styles/ReactSelectBootstrapStyle";
import { LoadingSpinner } from "../../components/LoadingSpinner";

type Props = {
    show: boolean;
    onClose: (added: boolean) => void;
    leadId: string | undefined;
};

const AddLeadProductModel: React.FC<Props> = ({ show, onClose, leadId }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [allProducts, setAllProducts] = useState<any[]>();

    const handleClose = () => onClose(false);

    useEffect(() => {
        Promise.all([productService.getAllListItems()]).then((response) => {
            setAllProducts(response[0]);
        });
    }, []);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    productId: "",
                    leadId: leadId ? leadId : "",
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    setSubmitting(false);
                    const newLeadProduct: ILeadProduct = {
                        id: undefined,
                        productId: values.productId,
                        leadId: leadId
                    };
                    orderService.addLeadProduct(newLeadProduct).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                onClose(true);
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                                setFieldError("productId", error.errors.ProductId);
                                setFieldError("leadId", error.errors.LeadId);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>New Lead Product</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group as={Row} className="mb-3" controlId="orderProductId">
                                <Form.Label column sm={3}>
                                    Product
                                </Form.Label>
                                <Col sm={9}>
                                    <Select
                                        styles={ReactSelectBootstrapStyle}
                                        className="stateManagedSelect"
                                        onChange={(value) => setFieldValue("productId", value == null ? "" : value.value)}
                                        isClearable
                                        options={allProducts}
                                    />
                                    <ErrorMessage name="productId" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                    {isSubmitting ? <LoadingSpinner text="Creating Lead Product..." /> : "Create Lead Product"}
                                </Button>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { AddLeadProductModel };
