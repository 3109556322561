import { IRole } from "@/customTypings/role";
import { useAtom } from "@dbeining/react-atom";
import { faFileExport, faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { useNavigate } from "react-router-dom";
import { userAtom } from "../../atoms/userAtom";
import { TaskComplete, TaskDueToday, TaskOpen, TaskOverdue } from "../../components/Badges";
import { ColumnRender, FilterType, IColumn, IFilter, IPagedListAction } from "../../components/PagedList/Declarations";
import { PagedList } from "../../components/PagedList/PagedList";
import { TaskStatus } from "../../constants/TaskStatus";
import { Role } from "../../helpers";
import { TaskAddModel } from "../../components/Models/TaskAddModel";

type Props = {
    customerNumber: string | undefined;
    customerId: string | undefined;
    address?: string;
};


const CustomerTasksPage: React.FC<Props> = ({ customerNumber, customerId, address = "" }) => {
    const navigate = useNavigate();
    const currentUser = useAtom(userAtom);
    const [showAddTask, setShowAddTask] = useState(false);
    const [refreshNumber, setRefeshNumber] = useState(0);


    const handleAddTask = (added: boolean) => {
        if (added) {
            setRefeshNumber((refreshNumber) => refreshNumber + 1);
        }
        setShowAddTask(false);
    };
    const handleOpenAddTask = () => {
        setShowAddTask(true);
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <PagedList
                            columns={[
                                {
                                    member: "name",
                                    renderAs: ColumnRender.customRenderer,
                                    title: "Task",
                                    width: 300,
                                    customRenderer: (item) => {
                                        return (
                                            <td>
                                                {item.name || item.taskTypeName}
                                            </td>
                                        );
                                    },
                                } as IColumn,
                                {
                                    member: "taskStatus",
                                    renderAs: ColumnRender.customRenderer,
                                    title: "Task Status",
                                    width: 150,
                                    customRenderer: (item) => {
                                        switch (item.taskStatus.toString()) {
                                            case TaskStatus[TaskStatus.Complete]:
                                                return (
                                                    <td>
                                                        <TaskComplete />
                                                    </td>
                                                );
                                            case TaskStatus[TaskStatus.Overdue]:
                                                return (
                                                    <td>
                                                        <TaskOverdue />
                                                    </td>
                                                );
                                            case TaskStatus[TaskStatus.DueToday]:
                                                return (
                                                    <td>
                                                        <TaskDueToday />
                                                    </td>
                                                );
                                            default:
                                                return (
                                                    <td>
                                                        <TaskOpen />
                                                    </td>
                                                );
                                        }
                                    },
                                } as IColumn,
                                {
                                    member: "creationDate",
                                    renderAs: ColumnRender.dateTime,
                                    title: "Start Time",
                                    width: 200,
                                } as IColumn,
                                {
                                    member: "dueDate",
                                    renderAs: ColumnRender.dateTime,
                                    title: "End/Due Time",
                                    width: 200,
                                } as IColumn,
                                {
                                    member: "userName",
                                    renderAs: ColumnRender.text,
                                    title: "Personnel",
                                    width: 200,
                                } as IColumn,
                            ]}
                            itemActions={[
                                {
                                    text: "Detail",
                                    onClick: (item) => {
                                        navigate(`/mytasks/${item.id}`);
                                    },
                                    primary: true,
                                } as IPagedListAction,
                            ]}
                            headActions={[
                                {
                                    icon: faPlus,
                                    text: "Add Task",
                                    onClick: handleOpenAddTask,
                                } as IPagedListAction,
                            ]}
                            filters={[
                                {
                                    title: "Task",
                                    member: "name",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Task Status",
                                    member: "taskStatus",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Start Time",
                                    member: "creationDate",
                                    type: FilterType.date,
                                } as IFilter,
                                {
                                    title: "End/Due Time",
                                    member: "dueDate",
                                    type: FilterType.date,
                                } as IFilter,
                                {
                                    title: "Personnel",
                                    member: "userName",
                                    type: FilterType.text,
                                } as IFilter,
                            ]}
                            defaultSortBy="name"
                            getUrl="api/task/alltasks"
                            rowFormatter={(item: any) => {
                                return null;
                            }}
                            persistanceKey="TaskListNewPage"
                            refreshNumber={refreshNumber + 1}
                            exportUrl={currentUser?.identity.roles.some((role: IRole) => [Role.Admin].indexOf(role.name) >= 0) ? "api/task/download" : undefined}
                            exportIcon={faFileExport}
                            staticFilterValues={{ customerNumber }}
                        />
                    </Col>
                </Row>
            </Container>
            <TaskAddModel customerId={customerId} show={showAddTask} onClose={handleAddTask} fromLead={true} location={address} />
        </>
    );
};

export { CustomerTasksPage };
