import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { ITaskType } from "../customTypings/TaskType";
import { taskService } from "../services";
import HHTModal from "../Shared/HHTModal";
import {
    ColumnRender, FilterType, IColumn, IFilter, IPagedListAction
} from "./PagedList/Declarations";
import { PagedList } from "./PagedList/PagedList";

interface ITaskTypeCategory {
  id: string;
  name: string;
  taskCategoryName: string;
}

function AddTaskType() {
  const [showAddTask, setShowAddTask] = useState(false);
  const [refreshNumber, setRefeshNumber] = useState(0);
  const [categories, setCategories] = useState<ITaskType[]>();
  const [showEditTask, setShowEditTask] = useState(false);
  const [task, setTask] = useState<ITaskTypeCategory>();
  const columns: IColumn[] = [];
  const filters: IFilter[] = [];
  const headActions: IPagedListAction[] = [];

  const handleAddTask = (added: boolean) => {
    if (added) {
      setRefeshNumber(refreshNumber + 1);
    }
    setShowAddTask(false);
  };
  const handleOpenAddTask = () => {
    setShowAddTask(true);
  };

  filters.push({
    title: "Name",
    member: "name",
    type: FilterType.text,
  } as IFilter);

  filters.push({
    title: "Task Category",
    member: "taskCategoryName",
    type: FilterType.text,
  } as IFilter);

  columns.push({
    member: "name",
    renderAs: ColumnRender.text,
    title: "Task Type",
    width: 100,
  } as IColumn);

  columns.push({
    member: "taskCategoryName",
    renderAs: ColumnRender.text,
    title: "Category",
    width: 100,
  } as IColumn);

  headActions.push({
    icon: faPlus,
    text: "Add Task Type",
    onClick: handleOpenAddTask,
  } as IPagedListAction);

  useEffect(() => {
    taskService.getTaskCategories().then((categories) => {
      setCategories(categories.results);
    });
  }, []);

  const addTaskType = async (values: any) => {
    const selectedCategory = (categories ?? []).find(
      (item) => item.name === values.taskcategory
    );

    const taskType: ITaskType = {
      id: undefined,
      name: values.name,
      taskcategoryid: selectedCategory?.id || "",
    };

    try {
      const res = await taskService.addTaskType(taskType);
      return res;
    } catch (error: any) {
      return { status: "Failure", message: error.title };
    }
  };

  const editTaskType = async (values: any) => {
    const selectedCategory = (categories ?? []).find(
      (item) => item.name === values.taskcategory
    );

    const taskType: ITaskType = {
      id: task?.id,
      name: values.name,
      taskcategoryid: selectedCategory?.id || "",
    };
    try {
      const res = await taskService.editTaskType(taskType);
      setRefeshNumber(refreshNumber + 1);
      return res;
    } catch (error: any) {
      return { status: "Failure", message: error.title };
    }
  };

  return (
    <>
      <Container fluid>
        <Helmet>
          <title>Task Types</title>
        </Helmet>
        <Row>
          <Col>
            <h1 className="my-3">Task Types</h1>
            <PagedList
              columns={columns}
              itemActions={[
                {
                  icon: faPen,
                  text: "Edit",
                  onClick: (item) => {
                    setTask(item);
                    setShowEditTask(true);
                  },
                } as IPagedListAction,
              ]}
              headActions={headActions}
              filters={filters}
              defaultSortBy="name"
              getUrl="api/task/tasktypes"
              avatarUrl="api/avatar"
              rowFormatter={(item: any) => {
                return null;
              }}
              refreshNumber={refreshNumber}
            />
            <HHTModal
              show={showAddTask}
              onClose={handleAddTask}
              callback={addTaskType}
              title="Task Type"
              fields={[{ name: "name", type: "text" }]}
              dropdown={{
                placeholders: (categories ?? []).map(
                  (category: any) => category.name
                ),
                title: "Add Category",
                name: "taskcategory",
              }}
            />
          </Col>
        </Row>
      </Container>

      <HHTModal
        show={showEditTask}
        onClose={() => setShowEditTask(false)}
        callback={editTaskType}
        title="Task Type"
        fields={[{ name: "name", type: "text", value: task?.name }]}
        dropdown={{
          placeholders: (categories ?? []).map(
            (category: any) => category.name
          ),
          title: task?.taskCategoryName as string,
          name: "taskcategory",
        }}
      />
    </>
  );
}

export default AddTaskType;
