import { ILead } from "@/customTypings/Lead";
import { ErrorMessage, Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { IAddress } from "../../customTypings/Address";
import { IContact } from "../../customTypings/Contact";
import { ILeadFull } from "../../customTypings/LeadFull";
import { useAtom } from "@dbeining/react-atom";
import Select from "react-select";

import {
    addressService,
    contactService,
    leadService,
    postcodeService,
    userService
} from "../../services";
import { ReactSelectBootstrapStyle } from "../../styles/ReactSelectBootstrapStyle";
import { userAtom } from "../../atoms/userAtom";
function LeadAddForm() {

    interface IAddressOption {
        line1: string;
        line2: string;
        town: string;
        postcode: string;
        country: string;
    }

    const [alertVariant, setAlertVariant] = useState("danger");
    const [allUsers, setAllUsers] = useState<any[]>();
    const [isLooking, setIsLooking] = useState(false);

    const [lookupOptions, setLookupOptions] = useState<{ value: string, label: string, address: IAddressOption }[]>([]);

    const mapPostcodeResponseToOptions = (response: any): { value: string, label: string, address: IAddressOption }[] => {
        return response?.delivery_points.map((deliveryPoint: any) => ({
            value: deliveryPoint.udprn,
            label: `${deliveryPoint.line_1}, ${deliveryPoint.line_2}, ${response.town}, ${response.postcode}`,
            address: {
                line1: deliveryPoint.line_1,
                line2: deliveryPoint.line_2 || "",
                town: response.town,
                postcode: response.postcode,
                country: response.country || "UK",
            }
        }));
    };

    const leadSourceOptions = [
        { value: "Website", label: "Website" },
        { value: "Walk-in", label: "Walk-in" },
        { value: "Telephone", label: "Telephone" },
        { value: "Email", label: "Email" },
    ];

    useEffect(() => {
        Promise.all([userService.getAllListItems()]).then((response) => {
            setAllUsers(response[0]);
        });
    }, []);


    const navigate = useNavigate();

    const currentUser = useAtom(userAtom);


    const initialValues: any = {
        leadNumber: "",
        title: "Mr.",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        line1: "",
        line2: "",
        town: "",
        country: "",
        postCode: ""
    };

    const generateValidationSchema = () => {
        const schemaObject: {
            [key: string]: Yup.StringSchema<string | undefined>;
        } = {};

        Object.keys(initialValues).forEach((field) => {
            let fieldSchema;
            if (field === "email") {
                fieldSchema = Yup.string()
                    .required(`email is required`)
                    .email("must be a valid email");
            } else if (field === "leadNumber") {
                fieldSchema = Yup.string().matches(/^\d+$/, "lead Number must contain only numbers");
            } else if (field === "phone") {
                fieldSchema = Yup.string()
                    .required("Phone Number is required")
                    .matches(
                        /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
                        "Invalid phone number"
                    );
            } else if (field === "line1" || field === "line2") {
                fieldSchema = Yup.string();
            } else {
                fieldSchema = Yup.string().required(`${field} is required`);
            }

            schemaObject[field] = fieldSchema;
        });
        return Yup.object().shape(schemaObject);
    };

    const save = async (
        values: any,
        setStatus: any,
        setSubmitting: any,
        setFieldError: any,
        resetForm: any
    ) => {
        const fullName = `${values.title} ${values.firstName} ${values.lastName}`.trim();

        const updatedContact: IContact = {
            id: undefined,
            name: fullName,
            emailAddress: values.email,
            mobilePhone: values.phone,
        };
        const updatedAddress: IAddress = {
            id: undefined,
            line1: values.line1,
            line2: values.line2 ?? null,
            line3: values.line3 ?? null,
            town: values.town,
            county: values.country,
            postcode: values.postCode,
            what3words: "null",
            latitude: "",
            longitude: "",
        };
        const contact = await contactService.add(updatedContact);
        const address = await addressService.add(updatedAddress);
        const updatedLead: ILead = {
            id: undefined,
            identityId: currentUser?.identity.id,
            leadNumber: `${values.leadNumber}`,
            name: fullName,
            isBusiness: true,
            dateOfInquiry: new Date(),
            notes: "",
            contactId: contact.id,
            addressId: address.id,
            assignedToPersonnel: values.assignedToPersonnel ?? currentUser?.identity.id,
            leadSource: values.leadSource ?? leadSourceOptions[0].value
        };
        leadService.add(updatedLead).then(
            (response) => {
                setSubmitting(false);
                if (response.status === "Success") {
                    resetForm({ values: "" });
                    setAlertVariant("success");
                    setStatus("Lead has been created successfully");
                    navigate("/leads")
                } else {
                    setAlertVariant("danger");
                    setStatus(response.message);
                }
            },
            (error) => {
                setAlertVariant("danger");
                setStatus(error.title);
            }
        );
    };

    const lookUpPostCode = (postcode: string, values: any, setStatus: any) => {
        setIsLooking(true);

        postcodeService.lookup(postcode).then((res) => {
            if (res.delivery_points !== null) {
                const options = mapPostcodeResponseToOptions(res);
                setAlertVariant("success");
                setStatus("Please select an address from the Postcode lookup dropdown");
                setLookupOptions(options);
                setIsLooking(false);
            } else {
                setAlertVariant("danger");
                setStatus("Postcode coule be found, Please lookup for some different postcode");
                setLookupOptions([]);
                setIsLooking(false);
            }
        });
    };


    const lookUpPostCodeUK = async (postcode: string, setStatus: any) => {
        try {
            setIsLooking(true);
            const response = await fetch('https://pcls1.craftyclicks.co.uk/json/rapidaddress', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    key: 'e3d98-465f2-8860b-06289',
                    postcode: postcode,
                    response: 'data_formatted',
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            if (!data.error_code) {
                const options = mapPostcodeResponseToOptions(data);
                setAlertVariant("success");
                setStatus("Please select an address from the Postcode lookup dropdown");
                setLookupOptions(options);
                setIsLooking(false);
            } else {
                setAlertVariant("danger");
                setStatus(data.error_msg);
                setLookupOptions([]);
                setIsLooking(false);
            }
        } catch (error) {
            console.error('Error fetching postcode data:', error);
            setAlertVariant('danger');
            setIsLooking(false)
        }
    };

    const fieldDisplayNames: any = {
        "ContactDepartment": "Contact Department",
        "Line1": "Address Line 1",
        "Line2": "Address Line 2"
    };
    return (
        <>
            <h1 className="my-3">Add Lead</h1>
            <Formik
                initialValues={{ ...initialValues }}
                validationSchema={generateValidationSchema}
                onSubmit={(
                    values,
                    { setStatus, setSubmitting, setFieldError, resetForm, setFieldValue }
                ) => {
                    save(values, setStatus, setSubmitting, setFieldError, resetForm);
                }}
            >
                {({
                    errors,
                    status,
                    touched,
                    isSubmitting,
                    handleSubmit,
                    values,
                    setStatus,
                    handleChange,
                    setFieldValue
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group
                            as={Row}
                            key="title"
                            className="mb-3"
                            controlId="title"
                        >
                            <Form.Label column sm={3}>
                                Title
                            </Form.Label>
                            <Col sm={9}>
                                <Field
                                    as="select"
                                    name="title"
                                    onChange={handleChange}
                                    value={values.title}
                                    className={`form-control ${errors.title && touched.title ? "is-invalid" : ""}`}
                                >
                                    <option value="Mr.">Mr.</option>
                                    <option value="Mrs.">Mrs.</option>
                                </Field>
                                <ErrorMessage
                                    name="title"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </Col>
                        </Form.Group>
                        {Object.keys(initialValues)
                            .filter(
                                (field) =>
                                    field !== "leadNumber" && field !== "postCode" && field !== "id" && field !== "title"
                            )
                            .map((field) => (
                                <Form.Group
                                    as={Row}
                                    key={field}
                                    className="mb-3"
                                    controlId={field}
                                >
                                    <Form.Label column sm={3}>
                                        {fieldDisplayNames[(field.charAt(0).toUpperCase() + field.slice(1))] || (field.charAt(0).toUpperCase() + field.slice(1))}
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Field
                                            name={field}
                                            onChange={handleChange}
                                            type="text"
                                            value={values[field as keyof ILeadFull]}
                                            className={`form-control ${errors[field as keyof ILeadFull] &&
                                                touched[field as keyof ILeadFull]
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                            name={field}
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </Col>
                                </Form.Group>
                            ))}
                        <Form.Group as={Row} className="mb-3" controlId="leadSource">
                            <Form.Label column sm={3}>
                                Lead Source
                            </Form.Label>
                            <Col sm={9}>
                                <Select
                                    styles={ReactSelectBootstrapStyle}
                                    className="stateManagedSelect"
                                    onChange={(value) => setFieldValue("leadSource", value == null ? "" : value.value)}
                                    defaultInputValue={leadSourceOptions[0].value}
                                    isClearable
                                    options={leadSourceOptions}
                                />
                                <ErrorMessage name="leadSource" component="div" className="invalid-feedback" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="assignedToPersonnel">
                            <Form.Label column sm={3}>
                                Assigned To Personnel
                            </Form.Label>
                            <Col sm={9}>
                                <Select
                                    styles={ReactSelectBootstrapStyle}
                                    className="stateManagedSelect"
                                    onChange={(value) => setFieldValue("assignedToPersonnel", value == null ? "" : value.value)}
                                    defaultInputValue={currentUser?.identity.displayName + ": " + currentUser?.identity.email}
                                    isClearable
                                    options={allUsers}
                                />
                                <ErrorMessage name="assignedToPersonnel" component="div" className="invalid-feedback" />
                            </Col>
                        </Form.Group>
                        <Form.Group
                            as={Row}
                            key="postCode"
                            className="mb-3"
                            controlId="postCode"
                        >
                            <Form.Label column sm={3}>
                                Post Code
                            </Form.Label>
                            <Col sm={4}>
                                <Row>
                                    <Col>
                                        <Field
                                            name="postCode"
                                            onChange={handleChange}
                                            type="text"
                                            value={values["postCode"] as keyof ILeadFull}
                                            className={`form-control ${errors["postCode" as keyof ILeadFull] &&
                                                touched["postCode" as keyof ILeadFull]
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                            name="postCode"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </Col>
                                    <Col>
                                        <Button
                                            variant="primary"
                                            onClick={() =>
                                                lookUpPostCode(values.postCode, values, setStatus)
                                                /*lookUpPostCodeUK(values.postCode, setStatus)*/
                                            }
                                        >
                                            {isLooking ? <LoadingSpinner text="Looking..." /> : "LookUp"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="postcodeLookup">
                            <Form.Label column sm={3}>
                                Postcode Lookup
                            </Form.Label>
                            <Col sm={9}>
                                <Select
                                    styles={ReactSelectBootstrapStyle}
                                    className="stateManagedSelect"
                                    options={lookupOptions}
                                    onChange={(selectedOption: { value: string, label: string, address: IAddressOption }) => {
                                        const { address } = selectedOption;
                                        setFieldValue("line1", address.line1);
                                        setFieldValue("line2", address.line2);
                                        setFieldValue("town", address.town);
                                        setFieldValue("postCode", address.postcode);
                                        setFieldValue("country", address.country);
                                    }}
                                />
                            </Col>
                        </Form.Group>


                        <Button
                            variant="primary"
                            disabled={isSubmitting}
                            type="submit"
                            className="me-2 d-flex align-items-end ms-auto"
                        >
                            {isSubmitting ? <LoadingSpinner text="Saving..." /> : "Save"}
                        </Button>

                        {status && (
                            <Alert variant={alertVariant} className="mt-3">
                                {status}
                            </Alert>
                        )}
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default LeadAddForm;