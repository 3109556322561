import { IRole } from "@/customTypings/role";
import { useAtom } from "@dbeining/react-atom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Link, useNavigate } from "react-router-dom";
import { userAtom } from "../../atoms/userAtom";
import { TaskComplete, TaskDueToday, TaskOpen, TaskOverdue } from "../../components/Badges";
import { ColumnRender, FilterType, IColumn, IFilter, IPagedListAction } from "../../components/PagedList/Declarations";
import { PagedList } from "../../components/PagedList/PagedList";
import { TaskStatus } from "../../constants/TaskStatus";
import { Role } from "../../helpers";
import { TaskAddModel } from "../../components/Models/TaskAddModel";
import { ITask } from "../../customTypings/Task";


type Props = {
    admin?: boolean | undefined;
};

const TaskListPage: React.FC<Props> = ({ admin }) => {
    const navigate = useNavigate();
    const currentUser = useAtom(userAtom);
    const [showAddTask, setShowAddTask] = useState(false);
    const [refreshNumber, setRefeshNumber] = useState(0);

    let taskStatusObject: any = [];
    for (const status in TaskStatus) {
        if (isNaN(Number(status))) {
            taskStatusObject.push({ value: status, label: status });
        }
    }

    const handleAddTask = (added: boolean) => {
        if (added) {
            setRefeshNumber((refreshNumber) => refreshNumber + 1);
        }
        setShowAddTask(false);
    };
    const handleOpenAddTask = () => {
        setShowAddTask(true);
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <h1 className="my-3">Tasks</h1>
                        <PagedList
                            columns={[
                                {
                                    member: "customerNumber",
                                    renderAs: currentUser?.identity.roles.some((role: IRole) => [Role.SystemAdmin].indexOf(role.name) >= 0)
                                        ? ColumnRender.customRenderer
                                        : ColumnRender.text,
                                    title: "Customer Number",
                                    customRenderer: currentUser?.identity.roles.some((role: IRole) => [Role.SystemAdmin].indexOf(role.name) >= 0)
                                        ? (item) => (
                                            <td>
                                                <Link to={`/customers/${item.customerId}`}>{item.customerNumber}</Link>
                                            </td>
                                        )
                                        : undefined,
                                    width: 100,
                                } as IColumn,
                                {
                                    member: "name",
                                    renderAs: ColumnRender.text,
                                    title: "Task",
                                    width: 100,
                                } as IColumn,
                                {
                                    member: "taskTypeName",
                                    renderAs: ColumnRender.text,
                                    title: "Type",
                                    width: 100,
                                } as IColumn,
                                {
                                    member: "customerName",
                                    renderAs: ColumnRender.text,
                                    title: "Customer",
                                    width: 100,
                                } as IColumn,
                                {
                                    member: "productName",
                                    renderAs: ColumnRender.text,
                                    title: "Product",
                                    width: 100,
                                } as IColumn,
                                {
                                    member: "location",
                                    renderAs: ColumnRender.text,
                                    title: "Location",
                                    width: 100,
                                } as IColumn,
                                {
                                    member: "taskStatus",
                                    renderAs: ColumnRender.customRenderer,
                                    title: "Task Status",
                                    width: 150,
                                    customRenderer: (item) => {
                                        switch (item.taskStatus.toString()) {
                                            case TaskStatus[TaskStatus.Complete]:
                                                return (
                                                    <td>
                                                        <TaskComplete />
                                                    </td>
                                                );
                                            case TaskStatus[TaskStatus.Overdue]:
                                                return (
                                                    <td>
                                                        <TaskOverdue />
                                                    </td>
                                                );
                                            case TaskStatus[TaskStatus.DueToday]:
                                                return (
                                                    <td>
                                                        <TaskDueToday />
                                                    </td>
                                                );
                                            default:
                                                return (
                                                    <td>
                                                        <TaskOpen />
                                                    </td>
                                                );
                                        }
                                    },
                                } as IColumn,
                                {
                                    member: "creationDate",
                                    renderAs: ColumnRender.dateTime,
                                    title: "Start Time",
                                    width: 100,
                                } as IColumn,
                                {
                                    member: "dueDate",
                                    renderAs: ColumnRender.dateTime,
                                    title: "End/Due Time",
                                    width: 100,
                                } as IColumn,
                                {
                                    member: "userName",
                                    renderAs: ColumnRender.text,
                                    title: "Personnel",
                                    width: 100,
                                } as IColumn,

                                ...(!admin
                                    ? [{
                                        member: "",
                                        renderAs: ColumnRender.customRenderer,
                                        title: "Created/Assigned",
                                        width: 100,
                                        customRenderer: (item: ITask) => {
                                            if (item.userId === currentUser?.identity.id && item.creatorId === currentUser?.identity.id) {
                                                return <td>Both</td>;
                                            } else if (item.userId === currentUser?.identity.id) {
                                                return <td>Assigned</td>;
                                            } else if (item.creatorId === currentUser?.identity.id) {
                                                return <td>Created</td>;
                                            } else {
                                                return <td></td>;
                                            }
                                        }
                                    }] : []
                                )
                            ]}
                            itemActions={[
                                {
                                    text: "Detail",
                                    onClick: (item) => {
                                        navigate(`/mytasks/${item.id}`);
                                    },
                                    primary: true,
                                } as IPagedListAction,
                            ]}
                            headActions={[
                                {
                                    icon: faPlus,
                                    text: "Add Task",
                                    onClick: handleOpenAddTask,
                                } as IPagedListAction,
                            ]}
                            filters={[
                                {
                                    title: "Customer Number",
                                    member: "customerNumber",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Task",
                                    member: "name",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Type",
                                    member: "taskTypeName",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Customer",
                                    member: "customerName",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Product",
                                    member: "productName",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Location",
                                    member: "location",
                                    type: FilterType.text,
                                } as IFilter,
                                {
                                    title: "Task Status",
                                    member: "taskStatusList",
                                    type: FilterType.multiselect,
                                    options: taskStatusObject,
                                    defaultValue: [
                                        { label: "Overdue", value: "Overdue" },
                                        { label: "Open", value: "Open" },
                                        { label: "DueToday", value: "DueToday" },
                                    ],
                                } as IFilter,
                                {
                                    title: "Start Time",
                                    member: "creationDate",
                                    type: FilterType.date,
                                } as IFilter,
                                {
                                    title: "End/Due Time",
                                    member: "dueDate",
                                    type: FilterType.date,
                                } as IFilter,
                                {
                                    title: "Personnel",
                                    member: "userName",
                                    type: FilterType.text,
                                } as IFilter,
                            ]}
                            defaultSortBy="dueDate"
                            getUrl={`api/task/${admin ? 'alltasks' : 'all'}`}
                            rowFormatter={(item: any) => {
                                return null;
                            }}
                            persistanceKey="TaskListNewPage"
                            refreshNumber={refreshNumber + 1}
                        />
                    </Col>
                </Row>
            </Container>
            <TaskAddModel customerId={null} show={showAddTask} onClose={handleAddTask} />
        </>
    );
};

export { TaskListPage };
