import { useAtom } from "@dbeining/react-atom";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Container, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { userAtom } from "../../atoms/userAtom";
import { Role } from "../../helpers";
import { orderService } from "../../services";
import HHTRow from "../../Shared/HHTRow";
import { OrderAccessoryListPage } from "../OrderAccessory/OrderAccessoryListPage";


const OrderDetailPage = () => {
    let params = useParams();
    const currentUser = useAtom(userAtom);
    const isAdmin = currentUser?.identity.roles.some(
        (role) => role.name === Role.SystemAdmin
    );

    const [order, setOrder] = useState<any>();

    useEffect(() => {
        getFullOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFullOrder = () => {
        if (params.id) {
            orderService.fullOrder(params.id).then((order) => {
                setOrder(order);
            });
        }
    }

    return (
        <Container fluid>
            <Helmet>
                <title>Product Order Details</title>
            </Helmet>
            {order && (
                <>
                    <Stack direction="horizontal" gap={3}>
                        <h3 className="my-3">Product Order Details</h3>
                    </Stack>
                    <HHTRow
                        label="Customer Number"
                        value={
                            isAdmin ? (
                                <div>
                                    <Link to={`/customers/${order.customerId}`}>{order.customerNumber}</Link>
                                </div>
                            ) : (
                                <div>
                                   {order.customerNumber}
                                </div>
                            )
                        }
                    />
                    <HHTRow label="Name" value={order.name} />
                    <HHTRow label="Serial No" value={order.serialNumber} />
                    <HHTRow label="Brand" value={order.brand} />
                    <HHTRow label="Model" value={order.model} />
                    <HHTRow label="Colour" value={order.color} />
                    <HHTRow label="Delivery Date" value={dayjs(order.deliveryDate).format("DD MMM YYYY")} />

                    < OrderAccessoryListPage />
                </>
            )}

        </Container>
    );
};
export { OrderDetailPage };
