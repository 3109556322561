import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ErrorMessage, Field } from "formik";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { ReactSelectBootstrapStyle } from "../../styles/ReactSelectBootstrapStyle";

dayjs.extend(utc);

type Props = {
    show: boolean;
    values: any;
    errors: any;
    touched: any;
    handleChange: any;
};

const RecurringAddTask: React.FC<Props> = ({ show, values, errors, touched, handleChange }) => {
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const recurrenceOptions = [
        { label: "Weekly", value: "weekly" },
        { label: "Monthly", value: "monthly" },
        { label: "Yearly", value: "yearly" },
    ];

    const [recurrenceType, setRecurrenceType] = useState("weeks");

    return (
        <div className="w-100">
            <Form.Group as={Row} className="mb-3" controlId="recurrenceType">
                <Form.Label column sm={3}>
                    Recurrence Type
                </Form.Label>
                <Col sm={9}>
                    <Select
                        options={recurrenceOptions}
                        value={recurrenceOptions.find(option => option.value === recurrenceType)}
                        onChange={(option) => {
                            setRecurrenceType(option?.value || "weekly");
                            const durationLabel = option?.value === "weekly" ? "Weeks" :
                                option?.value === "monthly" ? "Months" : "Years";
                            values["recursiveDuration"] = durationLabel;
                        }}
                        styles={ReactSelectBootstrapStyle}
                        className="stateManagedSelect"
                    />
                </Col>
            </Form.Group>

            {recurrenceType === "weekly" && (
                <Form.Group as={Row} className="mb-3" controlId="days">
                    <Form.Label column sm={3}>Day(s)</Form.Label>
                    <Col sm={9} className="pt-2">
                        {days.map((day, index) => (
                            <label key={index} className="d-block">
                                <Field
                                    type="checkbox"
                                    name="days"
                                    value={day}
                                    className="me-2"
                                />
                                {day}
                            </label>
                        ))}
                    </Col>
                </Form.Group>
            )}

            {recurrenceType === "monthly" && (
                <Form.Group as={Row} className="mb-3" controlId="dayOfMonth">
                    <Form.Label column sm={3}>Day of Month</Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            type="number"
                            name="dayOfMonth"
                            min={1}
                            max={31}
                            value={values.dayOfMonth}
                            className={"form-control" + (errors.dayOfMonth && touched.dayOfMonth ? " is-invalid" : "")}
                            onChange={handleChange}
                        />
                        <ErrorMessage name="dayOfMonth" component="div" className="invalid-feedback" />
                    </Col>
                </Form.Group>
            )}

            {recurrenceType === "yearly" && (
                <Form.Group as={Row} className="mb-3" controlId="yearlyDate">
                    <Form.Label column sm={3}>Date</Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            type="date"
                            name="yearlyDate"
                            value={values.yearlyDate}
                            className={"form-control" + (errors.yearlyDate && touched.yearlyDate ? " is-invalid" : "")}
                            onChange={handleChange}
                        />
                        <ErrorMessage name="yearlyDate" component="div" className="invalid-feedback" />
                    </Col>
                </Form.Group>
            )}

            <Form.Group as={Row} className="mb-3" controlId="startTime">
                <Form.Label column sm={3}>Start Time</Form.Label>
                <Col sm={3}>
                    <Form.Control
                        type="time"
                        name="startTime"
                        value={values.startTime}
                        className={"form-control" + (errors.startTime && touched.startTime ? " is-invalid" : "")}
                        onChange={handleChange}
                    />
                    <ErrorMessage name="startTime" component="div" className="invalid-feedback" />
                </Col>
                <Form.Label column sm={3}>Due/End Time</Form.Label>
                <Col sm={3}>
                    <Form.Control
                        type="time"
                        name="endTime"
                        value={values.endTime}
                        className={"form-control" + (errors.endTime && touched.endTime ? " is-invalid" : "")}
                        onChange={handleChange}
                    />
                    <ErrorMessage name="endTime" component="div" className="invalid-feedback" />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="duration">
                <Form.Label column sm={3}>Every</Form.Label>
                <Col sm={3}>
                    <Form.Control
                        type="number"
                        name="recursiveInterval"
                        value={values.recursiveInterval}
                        className={"form-control" + (errors.recursiveInterval && touched.recursiveInterval ? " is-invalid" : "")}
                        onChange={handleChange}
                        min="1"
                    />
                    <ErrorMessage name="recursiveInterval" component="div" className="invalid-feedback" />
                </Col>
                <Col sm={6}>
                    <Form.Control
                        type="text"
                        name="recursiveDuration"
                        value={recurrenceType === "weekly" ? "Weeks" : recurrenceType === "monthly" ? "Months" : "Years"}
                        className={"form-control" + (errors.recursiveDuration && touched.recursiveDuration ? " is-invalid" : "")}
                        readOnly
                    />
                    <ErrorMessage name="recursiveDuration" component="div" className="invalid-feedback" />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="commenceDate">
                <Form.Label column sm={3}>After Date</Form.Label>
                <Col sm={9}>
                    <Form.Control
                        type="date"
                        name="commenceDate"
                        value={values.commenceDate}
                        className={"form-control" + (errors.commenceDate && touched.commenceDate ? " is-invalid" : "")}
                        onChange={handleChange}
                    />
                    <ErrorMessage name="commenceDate" component="div" className="invalid-feedback" />
                </Col>
            </Form.Group>
        </div>
    );
};

export { RecurringAddTask };
